import { fetchWrapper, alert } from "@/helpers";
import { defineStore } from "pinia";

export const useProfileStore = defineStore("profile", {
  state: () => ({
    user: {},
    statuses: [
      { id: 1, name: "status 1" },
      { id: 2, name: "status 2" },
      { id: 3, name: "status 3" },
    ],
  }),
  actions: {
    async getProfile() {
      try {
        const result = await fetchWrapper.get(`api/users/profile`);
        this.user = result.data;
      } catch (error) {
        alert(error);
      }
    },
    async updateProfile() {
      try {
        const result = await fetchWrapper.post("api/users/profile", this.user);
        return result.data;
      } catch (error) {
        alert(error);
      }
    },
    async changePassword(current_password, password) {
      try {
        const result = await fetchWrapper.post(`api/users/change-password`, {
          current_password,
          password,
        });
        return result.data;
      } catch (error) {
        alert(error);
      }
    },
  },
});
