<template>
  <!-- Content area -->
  <div class="content">
    <!-- Basic layout -->
    <div class="card">
      <div class="card-header d-flex flex-wrap">
        <h6 class="mb-0">Product Price</h6>
        <div class="d-inline-flex ms-auto">
          <router-link
            :to="
              product.id
                ? `/product-prices?product_id=${product.id}`
                : `/product-prices`
            "
            class="text-body"
            ><i class="ph-x"></i
          ></router-link>
        </div>
      </div>

      <div class="card-body">
        <div>
          <div v-if="!product.id" class="row mb-3">
            <label class="col-lg-3 col-form-label">Product:</label>
            <div class="col-lg-9">
              <select
                class="form-control product"
                data-placeholder="Select Product"
              >
                <option></option>
                <option
                  v-if="productPrice.product"
                  :value="productPrice.product?.id"
                >
                  {{ productPrice.product?.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Name:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Name"
                v-model="productPrice.name"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Description:</label>
            <div class="col-lg-9">
              <textarea
                rows="3"
                cols="3"
                class="form-control"
                maxlength="511"
                placeholder="Description"
                v-model="productPrice.description"
              ></textarea>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Price:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="None"
                placeholder="Price"
                v-model="price"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Sale Price:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="None"
                placeholder="Sale Price"
                v-model="sale_price"
              />
            </div>
          </div>
          <div class="row mb-3" v-if="false">
            <label class="col-lg-3 col-form-label">Status:</label>
            <div class="col-lg-9">
              <select
                data-placeholder="Select Status"
                class="form-control status"
              >
                <option></option>
                <option v-for="s in statuses" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="text-end" v-if="productPrice.id || !id">
            <button type="submit" class="btn btn-primary" @click="onSubmit">
              Save <i class="ph-paper-plane-tilt ms-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /basic layout -->
  </div>
  <!-- /content area -->
</template>

<script setup>
import { onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { storeToRefs } from "pinia";
import { useAuthStore, useProductPriceStore, useProductStore } from "@/stores";

import { alert, select, selectSearch, numberFormat } from "@/helpers";

document.title = "Product Price";

const route = useRoute();
const id = route.params.id;
const authStore = useAuthStore();
const productPriceStore = useProductPriceStore();
const productStore = useProductStore();
const { productPrice, statuses } = storeToRefs(productPriceStore);
const { product } = storeToRefs(productStore);
productPrice.value = {};

const result = authStore.getPermission("product_price");
if ((result?.update !== true && id) || (result?.create !== true && !id)) {
  router.push("/login");
}

let productId = route.query.product_id;

async function init() {
  selectSearch.init("product", "products", (item) => {
    productPrice.value.product = item;
  });
  select.init("status", (item) => {
    productPrice.value.status = parseInt(item.id);
  });
  if (id) {
    await productPriceStore.getById(id);
    productPrice.value.product.name = "Abc";
    selectSearch.setValue("product", productPrice.value.product?.id);
    select.setValue("status", productPrice.value.status);
  } else {
    if (productId) {
      await productStore.getById(productId);
      if (product.value.id) {
        productPrice.value.product = { id: product.value.id };
        selectSearch.setValue("product", productPrice.value.product?.id);
      }
    }
  }
}

async function onSubmit() {
  if (!productPrice.value.product) {
    alert("Please enter Product");
    return;
  }
  if (!productPrice.value.name) {
    alert("Please enter Name");
    return;
  }
  if (!productPrice.value.price) {
    alert("Please enter Price");
    return;
  }
  // if (!productPrice.value.sale_price) {
  //   alert("Please enter Sale Price");
  //   return;
  // }

  const result = await productPriceStore.submit();
  if (result) {
    if (productId) {
      router.push(
        `/product-prices?product_id=${productPrice.value.product?.id}`,
      );
    } else {
      router.back();
    }
  }
}

onMounted(() => init());

const price = computed({
  get() {
    return numberFormat.format(productPrice.value.price);
  },
  set(newValue) {
    productPrice.value.price = numberFormat.getNumber(newValue);
  },
});

const sale_price = computed({
  get() {
    return numberFormat.format(productPrice.value.sale_price);
  },
  set(newValue) {
    productPrice.value.sale_price = numberFormat.getNumber(newValue);
  },
});
</script>
