<template>
  <!-- Content area -->
  <div class="content">
    <!-- Basic layout -->
    <div class="card">
      <div class="card-header d-flex flex-wrap">
        <h6 class="mb-0">Product Price</h6>
        <div class="d-inline-flex ms-auto">
          <router-link
            :to="
              productId
                ? `/product-prices?product_id=${productId}`
                : `/product-prices`
            "
            class="text-body"
            ><i class="ph-x"></i
          ></router-link>
        </div>
      </div>

      <div class="card-body disable">
        <div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Id:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                v-model="productPrice.id"
                disabled
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Created At:</label>
            <div class="col-lg-9">
              <input type="text" class="form-control created_at" disabled />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Updated At:</label>
            <div class="col-lg-9">
              <input type="text" class="form-control updated_at" disabled />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Product:</label>
            <div class="col-lg-9">
              <select
                class="form-control product"
                data-placeholder="‎"
                disabled
              >
                <option></option>
                <option
                  v-if="productPrice.product"
                  :value="productPrice.product?.id"
                >
                  {{ productPrice.product?.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Name:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                v-model="productPrice.name"
                disabled
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Description:</label>
            <div class="col-lg-9">
              <textarea
                rows="3"
                cols="3"
                class="form-control"
                v-model="productPrice.description"
                disabled
              ></textarea>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Price:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                v-model="price"
                disabled
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Sale Price:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                v-model="sale_price"
                disabled
              />
            </div>
          </div>
          <div class="row mb-3" v-if="false">
            <label class="col-lg-3 col-form-label">Status:</label>
            <div class="col-lg-9">
              <select class="form-control status" data-placeholder="‎" disabled>
                <option></option>
                <option v-for="s in statuses" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="text-end" v-if="productPrice.id">
            <button
              type="submit"
              class="btn btn-pink mx-2"
              @click="showConfirmDialog"
            >
              Delete <i class="ph-trash ms-2"></i>
            </button>
            <router-link
              :to="
                productId
                  ? `/product-prices/edit/${id}?product_id=${productId}`
                  : `/product-prices/edit/${id}`
              "
              class="btn btn-indigo"
              >Edit <i class="ph-pen ms-2"></i
            ></router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- /basic layout -->
  </div>
  <!-- /content area -->
  <ConfirmDialog ref="dialog" :callback="onDelete" />
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { storeToRefs } from "pinia";
import { useAuthStore, useProductPriceStore } from "@/stores";
import {
  alert,
  datepicker,
  select,
  selectSearch,
  numberFormat,
} from "@/helpers";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

document.title = "Product Price";

const authStore = useAuthStore();
const productPriceStore = useProductPriceStore();
const route = useRoute();
const id = route.params.id;
const { productPrice, statuses } = storeToRefs(productPriceStore);
productPrice.value = {};
const dialog = ref();

const result = authStore.getPermission("product_price");
if (result?.view !== true) {
  router.push("/login");
}

let productId = route.query.product_id;

async function init() {
  datepicker.init("created_at");
  datepicker.init("updated_at");
  selectSearch.init("product", "products");
  select.init("status");
  if (id) {
    await productPriceStore.getById(id);
    datepicker.setValue(
      "created_at",
      productPrice.value.created_at,
      "DD-MM-YYYY HH:mm:ss",
    );
    datepicker.setValue(
      "updated_at",
      productPrice.value.updated_at,
      "DD-MM-YYYY HH:mm:ss",
    );
    selectSearch.setValue("product", productPrice.value.product?.id);
    select.setValue("status", productPrice.value.status);
  }
}

function showConfirmDialog() {
  dialog.value.show("Click OK to delete.");
}
async function onDelete() {
  const result = await productPriceStore.delete(id);
  if (result) {
    alert("Delete successfully", "bg-teal");
    if (productId) {
      router.push(`/product-prices?product_id=${productId}`);
    } else {
      router.push("/product-prices");
    }
  }
}

onMounted(() => init());

const price = computed({
  get() {
    return numberFormat.format(productPrice.value.price);
  },
  set(newValue) {
    productPrice.value.price = numberFormat.getNumber(newValue);
  },
});

const sale_price = computed({
  get() {
    return numberFormat.format(productPrice.value.sale_price);
  },
  set(newValue) {
    productPrice.value.sale_price = numberFormat.getNumber(newValue);
  },
});
</script>
