<template>
  <!-- Content area -->
  <div class="content">
    <!-- Basic layout -->
    <div class="card">
      <div class="card-header d-flex flex-wrap">
        <h6 class="mb-0">Post Meta</h6>
        <div class="d-inline-flex ms-auto">
          <router-link to="/post-metas" class="text-body"
            ><i class="ph-x"></i
          ></router-link>
        </div>
      </div>

      <div class="card-body">
        <div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Post:</label>
            <div class="col-lg-9">
              <select class="form-control post" data-placeholder="Select Post">
                <option></option>
                <option v-if="postMeta.post" :value="postMeta.post?.id">
                  {{ postMeta.post?.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Key:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Key"
                v-model="postMeta.key"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Value:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Value"
                v-model="postMeta.value"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Type:</label>
            <div class="col-lg-9">
              <div class="form-check-horizontal">
                <label class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    v-model="postMeta.type"
                  />
                  <span class="form-check-label">Enable</span>
                </label>
              </div>
            </div>
          </div>
          <div class="row mb-3" v-if="false">
            <label class="col-lg-3 col-form-label">Status:</label>
            <div class="col-lg-9">
              <select
                data-placeholder="Select Status"
                class="form-control status"
              >
                <option></option>
                <option v-for="s in statuses" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="text-end" v-if="postMeta.id || !id">
            <button type="submit" class="btn btn-primary" @click="onSubmit">
              Save <i class="ph-paper-plane-tilt ms-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /basic layout -->
  </div>
  <!-- /content area -->
</template>

<script setup>
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { storeToRefs } from "pinia";
import { useAuthStore, usePostMetaStore } from "@/stores";

import { alert, select, selectSearch } from "@/helpers";

document.title = "Post Meta";

const route = useRoute();
const id = route.params.id;
const authStore = useAuthStore();
const postMetaStore = usePostMetaStore();
const { postMeta, statuses } = storeToRefs(postMetaStore);
postMeta.value = {};

const result = authStore.getPermission("post_meta");
if ((result?.update !== true && id) || (result?.create !== true && !id)) {
  router.push("/login");
}

async function init() {
  selectSearch.init("post", "posts", (item) => {
    postMeta.value.post = item;
  });
  select.init("status", (item) => {
    postMeta.value.status = parseInt(item.id);
  });
  if (id) {
    await postMetaStore.getById(id);
    selectSearch.setValue("post", postMeta.value.post?.id);
    select.setValue("status", postMeta.value.status);
  }
}

async function onSubmit() {
  if (!postMeta.value.post) {
    alert("Please enter Post");
    return;
  }
  // if (!postMeta.value.key) {
  //   alert("Please enter Key");
  //   return;
  // }
  // if (!postMeta.value.value) {
  //   alert("Please enter Value");
  //   return;
  // }
  // if (!postMeta.value.status) {
  //   alert("Please enter Status");
  //   return;
  // }

  const result = await postMetaStore.submit();
  if (result) {
    // router.push("/post-metas");
    router.back();
  }
}

onMounted(() => init());
</script>
