<!-- eslint-disable prettier/prettier -->
<template>
  <!-- Login form -->
  <form
    id="needs-validation"
    class="login-form"
    novalidate
    v-on:submit.prevent=""
  >
    <div class="card mb-0">
      <div class="card-body">
        <div class="text-center mb-3">
          <div
            class="d-inline-flex align-items-center justify-content-center mb-4 mt-2"
          >
            <img src="/assets/images/logo_icon.svg" class="h-48px" alt="" />
          </div>
          <h5 class="mb-0">Reset Password</h5>
          <span class="d-block text-muted"></span>
        </div>

        <div class="mb-3">
          <label class="form-label">Password</label>
          <div class="form-control-feedback form-control-feedback-start">
            <input
              type="password"
              class="form-control"
              placeholder="•••••••••••"
              v-model="user.password"
              autocomplete="new-password"
              required
            />
            <div class="form-control-feedback-icon">
              <i class="ph-lock text-muted"></i>
            </div>
            <div class="invalid-feedback">Invalid password</div>
          </div>
        </div>

        <div class="mb-3">
          <label class="form-label">Confirm Password</label>
          <div class="form-control-feedback form-control-feedback-start">
            <input
              type="password"
              class="form-control"
              placeholder="•••••••••••"
              v-model="user.confirm_password"
              autocomplete="new-password"
              required
            />
            <div class="form-control-feedback-icon">
              <i class="ph-lock text-muted"></i>
            </div>
            <div class="invalid-feedback">Invalid password</div>
          </div>
        </div>

        <div class="mb-3">
          <button type="submit" class="btn btn-primary w-100">
            Reset Password
          </button>
        </div>
      </div>
    </div>
  </form>
  <!-- /login form -->
</template>

<script setup>
import { reactive, onMounted } from "vue";
import { useAuthStore } from "@/stores";
import { formValidation, alert } from "@/helpers";
import { useRoute } from "vue-router";
import router from "@/router";

document.title = "Reset Password";

const user = reactive({
  password: "",
  confirm_password: "",
});

const route = useRoute();
const authStore = useAuthStore();
authStore.logout();

const token = route.query.token;
authStore.resetPassword(token, user.password).then((result) => {
  if (!result) {
    router.push("/login");
  }
});

onMounted(() =>
  formValidation(async () => {
    if (user.password != user.confirm_password) {
      alert("Mật khẩu không trùng khớp");
      return;
    }
    const result = await authStore.resetPassword(token, user.password);
    if (result) {
      alert("Thay đổi mật khẩu thành công", "bg-teal");
      router.push("/login");
    }
  }),
);
</script>
