import router from "@/router";
import { defineStore } from "pinia";
import { fetchWrapper, alert } from "@/helpers";
import { WEB_URL } from "@/const";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    user: JSON.parse(localStorage.getItem("user")),
    privilege: JSON.parse(localStorage.getItem("privilege")),
    returnUrl: null,
  }),
  getters: {},
  actions: {
    async login(email, password) {
      try {
        const result = await fetchWrapper.post("api/users/login", {
          email,
          password,
        });

        this.user = result.data;

        localStorage.setItem("user", JSON.stringify(this.user));
        return true;
      } catch (error) {
        alert(error);
      }
    },
    async register(name, email, password) {
      try {
        const result = await fetchWrapper.post("api/users/register", {
          name,
          email,
          password,
          domain: WEB_URL,
        });

        return result.data;
      } catch (error) {
        alert(error);
      }
    },
    async forgotPassword(email) {
      try {
        const result = await fetchWrapper.post("api/forgot-password", {
          email,
          domain: WEB_URL,
        });
        return result.data;
      } catch (error) {
        alert(error);
      }
    },
    async resetPassword(token, password) {
      try {
        const result = await fetchWrapper.post("api/reset-password", {
          token,
          password,
          domain: WEB_URL,
        });
        return result.data;
      } catch (error) {
        alert(error);
      }
    },
    async activeUser(token) {
      try {
        const result = await fetchWrapper.post(`api/users/active`, {
          token,
          domain: WEB_URL,
        });
        return result.data;
      } catch (error) {
        alert(error);
      }
    },
    async getPrivileges() {
      try {
        const result = await fetchWrapper.get("api/privilege");

        this.privilege = result.data ?? [];

        localStorage.setItem("privilege", JSON.stringify(this.privilege));

        router.push(this.returnUrl || "/");
      } catch (error) {
        alert(error);
      }
    },
    getPermission(code) {
      try {
        const privilege = this.privilege.find((x) => x.resource.code === code);
        if (privilege) {
          return privilege.permission;
        }
      } catch (error) {
        alert(error);
      }
    },
    getMenus(parentId) {
      try {
        return this.privilege
          .filter((a) => a.resource.parent_id === parentId)
          .map((a) => a.resource);
      } catch (error) {
        alert(error);
      }
    },
    async logout() {
      try {
        if (this.user?.token) {
          await fetchWrapper.post("api/users/logout");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.user = null;
        localStorage.removeItem("user");
      }
    },
    saveUser(u) {
      try {
        console.log(u);
        console.log("---");
        console.log(this.user);
        u.token = this.user.token;
        u.token_created_at = this.user.token_created_at;
        u.token_expiry = this.user.token_expiry;
        this.user = u;
        localStorage.setItem("user", JSON.stringify(this.user));
      } catch (error) {
        alert(error);
      }
    },
  },
});
