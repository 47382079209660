/* eslint-disable no-undef */
import { useAuthStore } from "@/stores/auth.store";
import { API_URL } from "@/const";

export const selectSearch = {
  init: init(),
  setValue: setValue(),
};

function init() {
  return (name, path, onChange) => {
    const { user } = useAuthStore();
    $("." + name)
      .select2({
        dropdownParent: $("." + name).parent(),
        ajax: {
          url: `${API_URL}api/${path}`,
          headers: {
            Authorization: `Bearer ${user?.token}`,
            "Content-Type": "application/json;charset=UTF-8",
          },
          delay: 250,
          data: function (params) {
            return {
              keyword: params.term,
              limit: 7,
            };
          },
          processResults: function (data) {
            return {
              results: data.data.map((x) => ({ id: x.id, text: x.name })),
            };
          },
          cache: true,
        },
        escapeMarkup: function (markup) {
          return markup;
        },
        minimumInputLength: 1,
        maximumInputLength: 10,
      })
      .on("select2:select", function (e) {
        const data = e.params.data;
        onChange({ id: data.id, name: data.text });
      })
      .on("select2:open", function (e) {
        const select2 = $(e.target).data("select2");
        select2.dropdown.$search.get(0).focus();
      });
  };
}

function setValue() {
  return (name, value) => {
    if (value) {
      $("." + name)
        .val(value || "")
        .trigger("change");
    }
  };
}
