<template>
  <!-- Content area -->
  <div class="content">
    <!-- Basic layout -->
    <div class="card">
      <div class="card-header d-flex flex-wrap">
        <h6 class="mb-0">User Forgot Password</h6>
        <div class="d-inline-flex ms-auto">
          <router-link to="/user-forgot-passwords" class="text-body"
            ><i class="ph-x"></i
          ></router-link>
        </div>
      </div>

      <div class="card-body">
        <div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">User:</label>
            <div class="col-lg-9">
              <select class="form-control user" data-placeholder="Select User">
                <option></option>
                <option
                  v-if="userForgotPassword.user"
                  :value="userForgotPassword.user?.id"
                >
                  {{ userForgotPassword.user?.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Token:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Token"
                v-model="userForgotPassword.token"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Expiry:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control expiry"
                placeholder="Expiry"
              />
            </div>
          </div>
          <div class="row mb-3" v-if="false">
            <label class="col-lg-3 col-form-label">Status:</label>
            <div class="col-lg-9">
              <select
                data-placeholder="Select Status"
                class="form-control status"
              >
                <option></option>
                <option v-for="s in statuses" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="text-end" v-if="userForgotPassword.id || !id">
            <button type="submit" class="btn btn-primary" @click="onSubmit">
              Save <i class="ph-paper-plane-tilt ms-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /basic layout -->
  </div>
  <!-- /content area -->
</template>

<script setup>
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { storeToRefs } from "pinia";
import { useAuthStore, useUserForgotPasswordStore } from "@/stores";

import { datepicker, select, selectSearch } from "@/helpers";

document.title = "User Forgot Password";

const route = useRoute();
const id = route.params.id;
const authStore = useAuthStore();
const userForgotPasswordStore = useUserForgotPasswordStore();
const { userForgotPassword, statuses } = storeToRefs(userForgotPasswordStore);
userForgotPassword.value = {};

const result = authStore.getPermission("user_forgot_password");
if ((result?.update !== true && id) || (result?.create !== true && !id)) {
  router.push("/login");
}

async function init() {
  selectSearch.init("user", "users", (item) => {
    userForgotPassword.value.user = item;
  });
  datepicker.init("expiry", (date) => {
    userForgotPassword.value.expiry = date;
  });
  select.init("status", (item) => {
    userForgotPassword.value.status = parseInt(item.id);
  });
  if (id) {
    await userForgotPasswordStore.getById(id);
    selectSearch.setValue("user", userForgotPassword.value.user?.id);
    datepicker.setValue("expiry", userForgotPassword.value.expiry);
    select.setValue("status", userForgotPassword.value.status);
  }
}

async function onSubmit() {
  // if (!userForgotPassword.value.user) {
  //   alert("Please enter User");
  //   return;
  // }
  // if (!userForgotPassword.value.token) {
  //   alert("Please enter Token");
  //   return;
  // }
  // if (!userForgotPassword.value.expiry) {
  //   alert("Please enter Expiry");
  //   return;
  // }
  // if (!userForgotPassword.value.status) {
  //   alert("Please enter Status");
  //   return;
  // }

  const result = await userForgotPasswordStore.submit();
  if (result) {
    // router.push("/user-forgot-passwords");
    router.back();
  }
}

onMounted(() => init());
</script>
