<template>
  <!-- Content area -->
  <div class="content">
    <!-- Basic layout -->
    <div class="card">
      <div class="card-header d-flex flex-wrap">
        <h6 class="mb-0">Order</h6>
        <div class="d-inline-flex ms-auto">
          <router-link to="/orders" class="text-body"
            ><i class="ph-x"></i
          ></router-link>
        </div>
      </div>

      <div class="card-body">
        <div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Name:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Name"
                v-model="order.name"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Address:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="511"
                placeholder="Address"
                v-model="order.address"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Phone:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Phone"
                v-model="order.phone"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Email:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Email"
                v-model="order.email"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Note:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Note"
                v-model="order.note"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Payment:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Payment"
                v-model="order.payment"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Is Paid:</label>
            <div class="col-lg-9">
              <div class="form-check-horizontal">
                <label class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    v-model="order.is_paid"
                  />
                  <span class="form-check-label">Enable</span>
                </label>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Total Price:</label>
            <div class="col-lg-9">
              <input
                type="number"
                class="form-control"
                placeholder="Total Price"
                v-model="total_price"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Utm Source:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Utm Source"
                v-model="order.utm_source"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Utm Medium:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Utm Medium"
                v-model="order.utm_medium"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Utm Campaign:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Utm Campaign"
                v-model="order.utm_campaign"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Utm Content:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Utm Content"
                v-model="order.utm_content"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Uuid:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="63"
                placeholder="Uuid"
                v-model="order.uuid"
              />
            </div>
          </div>
          <div class="row mb-3" v-if="false">
            <label class="col-lg-3 col-form-label">Status:</label>
            <div class="col-lg-9">
              <select
                data-placeholder="Select Status"
                class="form-control status"
              >
                <option></option>
                <option v-for="s in statuses" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="text-end" v-if="order.id || !id">
            <button type="submit" class="btn btn-primary" @click="onSubmit">
              Save <i class="ph-paper-plane-tilt ms-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /basic layout -->
  </div>
  <!-- /content area -->
</template>

<script setup>
import { onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { storeToRefs } from "pinia";
import { useAuthStore, useOrderStore } from "@/stores";

import { alert, select, numberFormat } from "@/helpers";

document.title = "Order";

const route = useRoute();
const id = route.params.id;
const authStore = useAuthStore();
const orderStore = useOrderStore();
const { order, statuses } = storeToRefs(orderStore);
order.value = {};

const result = authStore.getPermission("order");
if ((result?.update !== true && id) || (result?.create !== true && !id)) {
  router.push("/login");
}

async function init() {
  select.init("status", (item) => {
    order.value.status = parseInt(item.id);
  });
  if (id) {
    await orderStore.getById(id);
    select.setValue("status", order.value.status);
  }
}

async function onSubmit() {
  if (!order.value.name) {
    alert("Please enter Name");
    return;
  }
  if (!order.value.address) {
    alert("Please enter Address");
    return;
  }
  if (!order.value.phone) {
    alert("Please enter Phone");
    return;
  }
  if (!order.value.email) {
    alert("Please enter Email");
    return;
  }
  // if (!order.value.note) {
  //   alert("Please enter Note");
  //   return;
  // }
  if (!order.value.payment) {
    alert("Please enter Payment");
    return;
  }
  if (!order.value.total_price) {
    alert("Please enter Total Price");
    return;
  }
  // if (!order.value.utm_source) {
  //   alert("Please enter Utm Source");
  //   return;
  // }
  // if (!order.value.utm_medium) {
  //   alert("Please enter Utm Medium");
  //   return;
  // }
  // if (!order.value.utm_campaign) {
  //   alert("Please enter Utm Campaign");
  //   return;
  // }
  // if (!order.value.utm_content) {
  //   alert("Please enter Utm Content");
  //   return;
  // }
  // if (!order.value.uuid) {
  //   alert("Please enter Uuid");
  //   return;
  // }
  // if (!order.value.status) {
  //   alert("Please enter Status");
  //   return;
  // }

  const result = await orderStore.submit();
  if (result) {
    // router.push("/orders");
    router.back();
  }
}

onMounted(() => init());

const total_price = computed({
  get() {
    return numberFormat.format(order.value.total_price);
  },
  set(newValue) {
    order.value.total_price = numberFormat.getNumber(newValue);
  },
});
</script>
