export function formValidation(ok) {
  var form = document.getElementById("needs-validation");
  form.addEventListener(
    "submit",
    async function (e) {
      if (!form.checkValidity()) {
        e.preventDefault();
        e.stopPropagation();
      } else {
        ok();
      }

      form.classList.add("was-validated");
    },
    false,
  );
}
