<template>
  <!-- Content area -->
  <div class="content">
    <!-- Basic layout -->
    <div class="card">
      <div class="card-header d-flex flex-wrap">
        <h6 class="mb-0">Product</h6>
        <div class="d-inline-flex ms-auto">
          <router-link to="/products" class="text-body"
            ><i class="ph-x"></i
          ></router-link>
        </div>
      </div>

      <div class="card-body">
        <div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Name:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Name"
                v-model="product.name"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Sku:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Sku"
                v-model="product.sku"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Product Category:</label>
            <div class="col-lg-9">
              <select
                data-placeholder="Select Product Category"
                class="form-control product_category"
              >
                <option></option>
                <option
                  v-for="s in productCategories"
                  :key="s.id"
                  :value="s.id"
                >
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Description:</label>
            <div class="col-lg-9">
              <ckeditor
                class="form-control"
                :editor="editorDescription"
                v-model="product.description"
                :config="editorDescriptionConfig"
              ></ckeditor>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Detail:</label>
            <div class="col-lg-9">
              <ckeditor
                class="form-control"
                :editor="editorDetail"
                v-model="product.detail"
                :config="editorDetailConfig"
              ></ckeditor>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Photos:</label>
            <div class="col-lg-9">
              <div class="dropzone" id="photos"></div>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Best Seller:</label>
            <div class="col-lg-9">
              <div class="form-check-horizontal">
                <label class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    v-model="product.best_seller"
                  />
                  <span class="form-check-label">Enable</span>
                </label>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Sort order:</label>
            <div class="col-lg-9">
              <input
                type="number"
                class="form-control"
                placeholder="Sort Order"
                v-model="product.sort_order"
              />
            </div>
          </div>
          <div class="row mb-3" v-if="false">
            <label class="col-lg-3 col-form-label">Status:</label>
            <div class="col-lg-9">
              <select
                data-placeholder="Select Status"
                class="form-control status"
              >
                <option></option>
                <option v-for="s in statuses" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="text-end" v-if="product.id || !id">
            <button type="submit" class="btn btn-primary" @click="onSubmit">
              Save <i class="ph-paper-plane-tilt ms-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /basic layout -->
  </div>
  <!-- /content area -->
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { storeToRefs } from "pinia";
import { useAuthStore, useProductStore } from "@/stores";

import { select, dropzone, MyUploadAdapter } from "@/helpers";
// import { API_URL } from "@/const";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

document.title = "Product";

const route = useRoute();
const id = route.params.id;
const authStore = useAuthStore();
const productStore = useProductStore();
const { product, productCategories, statuses } = storeToRefs(productStore);
product.value = {};

const editorDescription = ref(ClassicEditor);
const editorDescriptionConfig = ref({
  mediaEmbed: { previewsInData: true },
  extraPlugins: [
    function (editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
      };
    },
  ],
});
const editorDetail = ref(ClassicEditor);
const editorDetailConfig = ref({
  mediaEmbed: { previewsInData: true },
  extraPlugins: [
    function (editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
      };
    },
  ],
});

const result = authStore.getPermission("product");
if ((result?.update !== true && id) || (result?.create !== true && !id)) {
  router.push("/login");
}

productStore.getProductCategories();

async function init() {
  select.init("product_category", (item) => {
    product.value.product_category = item;
  });
  dropzone.init("photos", "products/photos", (items) => {
    product.value.photos = items;
  });
  select.init("status", (item) => {
    product.value.status = parseInt(item.id);
  });
  if (id) {
    await productStore.getById(id);
    select.setValue("product_category", product.value.product_category?.id);
    dropzone.setValue("photos", product.value.photos || []);
    select.setValue("status", product.value.status);
  }
}

async function onSubmit() {
  // if (!product.value.name) {
  //   alert("Please enter Name");
  //   return;
  // }
  // if (!product.value.sku) {
  //   alert("Please enter Sku");
  //   return;
  // }
  // if (!product.value.product_category) {
  //   alert("Please enter Product Category");
  //   return;
  // }
  // if (!product.value.description) {
  //   alert("Please enter Description");
  //   return;
  // }
  // if (!product.value.detail) {
  //   alert("Please enter Detail");
  //   return;
  // }
  // if (!product.value.photos) {
  //   alert("Please enter Photos");
  //   return;
  // }
  // if (!product.value.status) {
  //   alert("Please enter Status");
  //   return;
  // }

  const result = await productStore.submit();
  if (result) {
    // router.push("/products");
    router.back();
  }
}

onMounted(() => init());
</script>
