<template>
  <!-- Danger modal -->
  <div id="modal_confirm" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header bg-danger text-white border-0">
          <h6 class="modal-title">Confirm</h6>
          <button
            type="button"
            class="btn-close btn-close-white"
            data-bs-dismiss="modal"
          ></button>
        </div>

        <div class="modal-body">
          <p>Star light, star bright</p>
          <p>First star I see tonight</p>
          <p>Wish I may, wish I might</p>
          <p>Have the wish I wish tonight</p>
          <p>{{ message }}</p>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-link" data-bs-dismiss="modal">
            Cancel
          </button>
          <button type="button" class="btn btn-danger" @click="confirm">
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- /default modal -->
</template>

<script>
import { ref } from "vue";
export default {
  props: ["callback"],
  setup(props) {
    const message = ref("");

    function show(m) {
      message.value = m;
      // eslint-disable-next-line no-undef
      var modal = bootstrap.Modal.getOrCreateInstance(
        document.getElementById("modal_confirm"),
      );
      modal.show();
    }

    function confirm() {
      // eslint-disable-next-line no-undef
      var modal = bootstrap.Modal.getOrCreateInstance(
        document.getElementById("modal_confirm"),
      );
      modal.hide();
      props.callback();
    }

    return { message, show, confirm };
  },
};
</script>
