<template>
  <!-- Content area -->
  <div class="content">
    <!-- table borders -->
    <div class="card" v-if="permission?.view">
      <div class="card-header d-lg-flex">
        <div class="d-flex">
          <h4 class="mb-0">User</h4>
          <a
            href="#page_header"
            class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto"
            data-bs-toggle="collapse"
          >
            <i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
          </a>
        </div>

        <div class="collapse d-lg-block my-lg-auto ms-lg-auto" id="page_header">
          <div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
            <div
              class="form-control-feedback form-control-feedback-end flex-fill"
            >
              <input
                type="text"
                class="form-control"
                placeholder="Search"
                v-model="option.keyword"
                v-on:keyup.esc="onEsc"
                v-on:keyup.enter="onEnter"
              />
              <div class="form-control-feedback-icon">
                <i class="ph-magnifying-glass opacity-50"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th>
                <a href="#" @click="orderBy('name')">
                  Name
                  <span
                    v-if="option.orderBy === 'name' && option.sortBy === 'asc'"
                    >&darr;</span
                  >
                  <span
                    v-if="option.orderBy === 'name' && option.sortBy == 'desc'"
                    >&uarr;</span
                  >
                </a>
              </th>
              <th>
                <a href="#" @click="orderBy('phone')">
                  Phone
                  <span
                    v-if="option.orderBy === 'phone' && option.sortBy === 'asc'"
                    >&darr;</span
                  >
                  <span
                    v-if="option.orderBy === 'phone' && option.sortBy == 'desc'"
                    >&uarr;</span
                  >
                </a>
              </th>
              <th>
                <a href="#" @click="orderBy('email')">
                  Email
                  <span
                    v-if="option.orderBy === 'email' && option.sortBy === 'asc'"
                    >&darr;</span
                  >
                  <span
                    v-if="option.orderBy === 'email' && option.sortBy == 'desc'"
                    >&uarr;</span
                  >
                </a>
              </th>
              <th>Avatar</th>
              <th>
                <a href="#" @click="orderBy('dob')">
                  Date of Birth
                  <span
                    v-if="option.orderBy === 'dob' && option.sortBy === 'asc'"
                    >&darr;</span
                  >
                  <span
                    v-if="option.orderBy === 'dob' && option.sortBy == 'desc'"
                    >&uarr;</span
                  >
                </a>
              </th>
              <th>
                <a href="#" @click="orderBy('address')">
                  Address
                  <span
                    v-if="
                      option.orderBy === 'address' && option.sortBy === 'asc'
                    "
                    >&darr;</span
                  >
                  <span
                    v-if="
                      option.orderBy === 'address' && option.sortBy == 'desc'
                    "
                    >&uarr;</span
                  >
                </a>
              </th>
              <th>
                <a href="#" @click="orderBy('password')">
                  Password
                  <span
                    v-if="
                      option.orderBy === 'password' && option.sortBy === 'asc'
                    "
                    >&darr;</span
                  >
                  <span
                    v-if="
                      option.orderBy === 'password' && option.sortBy == 'desc'
                    "
                    >&uarr;</span
                  >
                </a>
              </th>
              <th>
                <a href="#" @click="orderBy('salt')">
                  Salt
                  <span
                    v-if="option.orderBy === 'salt' && option.sortBy === 'asc'"
                    >&darr;</span
                  >
                  <span
                    v-if="option.orderBy === 'salt' && option.sortBy == 'desc'"
                    >&uarr;</span
                  >
                </a>
              </th>
              <th>
                <a href="#" @click="orderBy('role')">
                  Role
                  <span
                    v-if="option.orderBy === 'role' && option.sortBy === 'asc'"
                    >&darr;</span
                  >
                  <span
                    v-if="option.orderBy === 'role' && option.sortBy == 'desc'"
                    >&uarr;</span
                  >
                </a>
              </th>
              <th class="text-center w125">
                <router-link
                  to="/users/add"
                  class="text-primary"
                  v-if="permission?.create"
                >
                  <i
                    class="ph-plus-circle"
                    data-bs-popup="tooltip"
                    title="Add"
                  ></i>
                </router-link>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="users.length">
              <tr v-for="user in users" :key="user.id">
                <td>{{ user.name }}</td>
                <td>{{ user.phone }}</td>
                <td>{{ user.email }}</td>
                <td>
                  <a
                    v-if="user.avatar"
                    :href="API_URL + user.avatar.path"
                    target="_blank"
                  >
                    <img
                      :src="API_URL + user.avatar.path"
                      :alt="user.avatar.name"
                      width="64"
                    />
                  </a>
                </td>
                <td>
                  {{
                    dateFormat.formatDateString(user.dob, "DD-MM-YYYY HH:mm:ss")
                  }}
                </td>
                <td>{{ user.address }}</td>
                <td>{{ user.password }}</td>
                <td>{{ user.salt }}</td>
                <td>{{ user.role?.name }}</td>
                <td class="text-center">
                  <router-link
                    :to="`/users/${user.id}`"
                    class="text-info me-1"
                    v-if="permission?.view"
                  >
                    <i class="ph-eye"></i>
                  </router-link>
                  <router-link
                    :to="`/users/edit/${user.id}`"
                    class="text-indigo me-1"
                    v-if="permission?.update"
                  >
                    <i class="ph-pen"></i>
                  </router-link>
                  <a
                    href="#"
                    class="text-pink"
                    @click="showConfirmDialog(user)"
                    v-if="permission?.delete"
                    ><i class="ph-trash"></i
                  ></a>
                </td>
              </tr>
            </template>
            <tr v-if="users.loading">
              <td colspan="10" class="text-center">
                <div
                  class="spinner-border text-primary me-2"
                  role="status"
                ></div>
                <div
                  class="spinner-border text-danger me-2"
                  role="status"
                ></div>
                <div class="spinner-border text-success" role="status"></div>
              </td>
            </tr>
            <tr v-if="users.error">
              <td colspan="10">
                <div class="text-danger">Error: {{ users.error }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- /table borders -->

    <template v-if="paging.pages">
      <ul class="pagination justify-content-end">
        <li class="page-item" v-bind:class="paging.older ? '' : 'disabled'">
          <a href="#" class="page-link" @click="changePage(paging.older)"
            >&larr;</a
          >
        </li>
        <li class="page-item" v-for="page in paging.pages" :key="page">
          <a
            href="#"
            class="page-link"
            v-bind:class="page == paging.page ? 'active' : ''"
            @click="changePage(page)"
          >
            {{ page }}</a
          >
        </li>
        <li class="page-item" v-bind:class="paging.newer ? '' : 'disabled'">
          <a href="#" class="page-link" @click="changePage(paging.newer)"
            >&rarr;</a
          >
        </li>
      </ul>
    </template>
  </div>
  <!-- /content area -->
  <ConfirmDialog ref="dialog" :callback="onDelete" />
</template>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useAuthStore, useUserStore } from "@/stores";
import { dateFormat, alert } from "@/helpers";
import { API_URL } from "@/const";
import router from "@/router";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

document.title = "User";

const authStore = useAuthStore();
const userStore = useUserStore();
const permission = ref();
const dialog = ref();
const { users, paging, option } = storeToRefs(userStore);
let id = "";

const result = authStore.getPermission("user");
if (result?.view) {
  permission.value = result;
} else {
  router.push("/login");
}

userStore.getUsers();

function orderBy(c) {
  option.value.orderBy = c;
  if (option.value.sortBy == "asc") {
    option.value.sortBy = "desc";
  } else {
    option.value.sortBy = "asc";
  }
  userStore.getUsers();
}

function changePage(p) {
  option.value.page = p;
  userStore.getUsers();
}

function onEnter() {
  userStore.getUsers();
}

function onEsc() {
  option.value.keyword = null;
  userStore.getUsers();
}

function showConfirmDialog(user) {
  id = user.id;
  dialog.value.show(`Nhấn OK để xoá.`);
}

async function onDelete() {
  const result = await userStore.delete(id);
  if (result) {
    alert("Xoá thành công", "bg-teal");
    userStore.getUsers();
  }
}
</script>
