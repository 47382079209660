<template>
  <!-- Content area -->
  <div class="content">
    <!-- table borders -->
    <div class="card" v-if="permission?.view">
      <div class="card-header d-lg-flex">
        <div class="d-flex">
          <h4 class="mb-0">{{ option.parent_name || "Post" }}</h4>
          <a
            href="#page_header"
            class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto"
            data-bs-toggle="collapse"
          >
            <i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
          </a>
        </div>

        <div class="collapse d-lg-block my-lg-auto ms-lg-auto" id="page_header">
          <div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
            <div
              v-show="!option.parent_id"
              class="form-control-feedback form-control-feedback-end flex-fill"
              style="min-width: 228px"
            >
              <select data-placeholder="Select Type" class="form-control type">
                <option disabled>Type</option>
                <option v-for="t in types" :key="t" :value="t">
                  {{ t }}
                </option>
              </select>
            </div>
          </div>
          <div v-if="option.parent_id" class="d-inline-flex ms-auto">
            <a href="javascript:void(0)" class="text-body" @click="close()"
              ><i class="ph-x"></i
            ></a>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th>
                <a href="javascript:void(0)" @click="orderBy('title')">
                  Title
                  <span
                    v-if="option.orderBy === 'title' && option.sortBy === 'asc'"
                    >&darr;</span
                  >
                  <span
                    v-if="option.orderBy === 'title' && option.sortBy == 'desc'"
                    >&uarr;</span
                  >
                </a>
              </th>
              <th>Path</th>
              <th>
                <a href="javascript:void(0)" @click="orderBy('sort_order')">
                  Sort Order
                  <span
                    v-if="
                      option.orderBy === 'sort_order' && option.sortBy === 'asc'
                    "
                    >&darr;</span
                  >
                  <span
                    v-if="
                      option.orderBy === 'sort_order' && option.sortBy == 'desc'
                    "
                    >&uarr;</span
                  >
                </a>
              </th>
              <th class="text-center w140">
                <a
                  href="javascript:void(0)"
                  class="text-primary"
                  v-if="permission?.create"
                  @click="add()"
                >
                  <i
                    class="ph-plus-circle"
                    data-bs-popup="tooltip"
                    title="Add"
                  ></i>
                </a>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="posts.length">
              <tr v-for="post in posts" :key="post.id">
                <td>{{ post.title }}</td>
                <td>{{ post.path }}</td>
                <td>{{ post.sort_order }}</td>
                <td class="text-center">
                  <a
                    v-if="!option.parent_id"
                    href="javascript:void(0)"
                    class="text-warning me-1"
                    @click="getPostChildren(post)"
                  >
                    <i class="ph-tree-structure"></i>
                  </a>
                  <a
                    v-if="permission?.update"
                    href="javascript:void(0)"
                    class="text-indigo me-1"
                    @click="edit(post.id)"
                  >
                    <i class="ph-pen"></i>
                  </a>
                  <a
                    v-if="permission?.delete"
                    href="javascript:void(0)"
                    class="text-pink"
                    @click="showConfirmDialog(post)"
                    ><i class="ph-trash"></i
                  ></a>
                </td>
              </tr>
            </template>
            <tr v-if="posts.loading">
              <td colspan="9" class="text-center">
                <div
                  class="spinner-border text-primary me-2"
                  role="status"
                ></div>
                <div
                  class="spinner-border text-danger me-2"
                  role="status"
                ></div>
                <div class="spinner-border text-success" role="status"></div>
              </td>
            </tr>
            <tr v-if="posts.error">
              <td colspan="9">
                <div class="text-danger">Error: {{ posts.error }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- /table borders -->

    <template v-if="paging.pages">
      <ul class="pagination justify-content-end">
        <li class="page-item" v-bind:class="paging.older ? '' : 'disabled'">
          <a
            href="javascript:void(0)"
            class="page-link"
            @click="changePage(paging.older)"
            >&larr;</a
          >
        </li>
        <li class="page-item" v-for="page in paging.pages" :key="page">
          <a
            href="javascript:void(0)"
            class="page-link"
            v-bind:class="page == paging.page ? 'active' : ''"
            @click="changePage(page)"
          >
            {{ page }}</a
          >
        </li>
        <li class="page-item" v-bind:class="paging.newer ? '' : 'disabled'">
          <a
            href="javascript:void(0)"
            class="page-link"
            @click="changePage(paging.newer)"
            >&rarr;</a
          >
        </li>
      </ul>
    </template>
  </div>
  <!-- /content area -->
  <ConfirmDialog ref="dialog" :callback="onDelete" />
</template>

<script setup>
import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useAuthStore, usePostStore } from "@/stores";
import { select, alert } from "@/helpers";
import { useRoute } from "vue-router";
import router from "@/router";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

document.title = "Post";

const route = useRoute();
const authStore = useAuthStore();
const postStore = usePostStore();
const permission = ref();
const dialog = ref();
const { posts, types, paging, option } = storeToRefs(postStore);
let id = "";

const result = authStore.getPermission("post");
if (result?.view) {
  permission.value = result;
} else {
  router.push("/login");
}

function getPostChildren(p) {
  option.value.page = 1;
  option.value.parent_id = p.id;
  option.value.parent_name = p.title || p.name;
  router.push(`/posts?parent_id=${option.value.parent_id}`);
  postStore.getPosts();
}

async function close() {
  option.value.page = 1;
  option.value.parent_id = null;
  option.value.parent_name = null;
  if (option.value.type) {
    await router.push(`/posts?type=${option.value.type}`);
  } else {
    await router.push(`/posts`);
  }
  init();
}

function add() {
  let query = [];
  if (option.value.parent_id) {
    query.push(`parent_id=${option.value.parent_id}`);
  }
  if (option.value.type) {
    query.push(`type=${option.value.type}`);
  }
  router.push(`/posts/add${query.length ? "?" + query.join("&") : ""}`);
}

function edit(id) {
  let query = [];
  if (option.value.parent_id) {
    query.push(`parent_id=${option.value.parent_id}`);
  } else if (option.value.type) {
    query.push(`type=${option.value.type}`);
  }
  router.push(`/posts/edit/${id}${query.length ? "?" + query.join("&") : ""}`);
}

function orderBy(c) {
  option.value.orderBy = c;
  if (option.value.sortBy == "asc") {
    option.value.sortBy = "desc";
  } else {
    option.value.sortBy = "asc";
  }
  postStore.getPosts();
}

function changePage(p) {
  option.value.page = p;
  postStore.getPosts();
}

function showConfirmDialog(post) {
  id = post.id;
  dialog.value.show(`Nhấn OK để xoá.`);
}

async function onDelete() {
  const result = await postStore.delete(id);
  if (result) {
    alert("Xoá thành công", "bg-teal");
    postStore.getPosts();
  }
}

function init() {
  let type = route.query.type;
  let parentId = route.query.parent_id;

  if (parentId) {
    option.value.parent_id = parentId;
  }
  if (type) {
    option.value.type = type;
  }
  option.value.page = 1;
  postStore.getPosts();

  select.init("type", (item) => {
    console.log(item);
    option.value.page = 1;
    option.value.type = item.id;
    router.push(`/posts?type=${option.value.type}`);
    postStore.getPosts();
  });

  postStore.getTypes().then(() => {
    if (types.value) {
      if (option.value.type) {
        select.setValue("type", option.value.type);
      } else if (option.value.parent_id) {
        // pass
        console.log("pass", option.value.parent_id);
      } else {
        option.value.page = 1;
        option.value.type = types.value[0];
        router.push(`/posts?type=${option.value.type}`);
        postStore.getPosts();
      }
    }
  });
}

onMounted(() => init());
</script>
