import { fetchWrapper, alert } from "@/helpers";
import { paging } from "@/helpers/paging";
import { defineStore } from "pinia";

export const useReviewStore = defineStore("review", {
  state: () => ({
    reviews: {},
    paging: {},
    option: {},
    review: {},
    tags: [],
    types: [
      { id: 0, name: "Chưa phân loại" },
      { id: 1, name: "Em bé" },
      { id: 2, name: "Trẻ em" },
      { id: 3, name: "Người lớn" },
      { id: 4, name: "Mặt" },
      { id: 5, name: "Mụn" },
      { id: 6, name: "Khác" },
    ],
    statuses: [
      { id: 0, name: "Xoá" },
      { id: 1, name: "Chờ duyệt" },
      { id: 2, name: "Hàn Quốc" },
      { id: 3, name: "Việt Nam" },
      { id: 4, name: "Top" },
    ],
  }),
  actions: {
    async getReviews() {
      // this.reviews = { loading: true };
      try {
        let query = [];
        if (this.option.page) {
          query.push(`page=${this.option.page}`);
        }
        if (this.option.keyword) {
          query.push(`keyword=${this.option.keyword.trim()}`);
        }
        if (this.option.orderBy && this.option.sortBy) {
          query.push(`order_by=${this.option.orderBy}`);
          query.push(`sort_by=${this.option.sortBy}`);
        }

        const result = await fetchWrapper.get(
          `api/reviews${query.length ? "?" + query.join("&") : ""}`,
        );
        this.reviews = result.data;

        // paging
        let p = result.paging;
        this.paging = paging(p.page, p.limit, p.total);
      } catch (error) {
        this.reviews = { error };
      }
    },
    async getById(id) {
      try {
        const result = await fetchWrapper.get(`api/reviews/${id}`);
        this.review = result.data;
      } catch (error) {
        alert(error);
      }
    },
    async getProducts(keyword) {
      try {
        const result = await fetchWrapper.get(
          `api/products?keyword=${keyword}`,
        );
        this.products = result.data;
      } catch (error) {
        alert(error);
      }
    },
    async getTags() {
      try {
        const result = await fetchWrapper.get("api/review-tags");
        this.tags = result.data;
      } catch (error) {
        alert(error);
      }
    },
    async submit() {
      try {
        if (this.review.id) {
          const result = await fetchWrapper.put(
            `api/reviews/${this.review.id}`,
            this.review,
          );
          return result;
        } else {
          const result = await fetchWrapper.post("api/reviews", this.review);
          return result;
        }
      } catch (error) {
        alert(error);
      }
    },
    async delete(id) {
      try {
        const result = await fetchWrapper.delete(`api/reviews/${id}`);
        return result.data;
      } catch (error) {
        alert(error);
      }
    },
  },
});
