import { useAuthStore } from "@/stores/auth.store";
import { API_URL } from "@/const";
import { alert } from "@/helpers";
import axios from "axios";

export function upload(file, type, onSuccess) {
  const { user } = useAuthStore();
  let formData = new FormData();
  formData.append("file", file);
  formData.append("folder", type);
  axios({
    method: "POST",
    url: `${API_URL}api/upload`,
    headers: {
      Authorization: `Bearer ${user?.token}`,
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  })
    .then((response) => {
      onSuccess(response.data.data);
    })
    .catch((error) => {
      alert(error);
    });
}
