import Noty from "noty";

export function alert(text, bg = "bg-pink") {
  console.log(text);
  new Noty({
    theme: `name ${bg}`,
    layout: "topRight",
    type: "error",
    timeout: 3000,
    text: text,
  }).show();
}
// bg-pink, bg-teal, bg-purple, bg-indigo
