import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores";
import homeRoutes from "./home.route";
import authenticateRoute from "./authenticate.route";
import resourceRoute from "./resource.route";
import productRoute from "./product.route";
import privilegeRoute from "./privilege.route";
import postMetaRoute from "./post_meta.route";
import orderRoute from "./order.route";
import contactRoute from "./contact.route";
import reviewTagRoute from "./review_tag.route";
import socialRoute from "./social.route";
import productCategoryRoute from "./product_category.route";
import orderDetailRoute from "./order_detail.route";
import userRoute from "./user.route";
import productPriceRoute from "./product_price.route";
import emailTemplateRoute from "./email_template.route";
import emailRecipientRoute from "./email_recipient.route";
import userForgotPasswordRoute from "./user_forgot_password.route";
import postRoute from "./post.route";
import bannerRoute from "./banner.route";
import emailSenderRoute from "./email_sender.route";
import reviewRoute from "./review.route";
import optionRoute from "./option.route";
import roleRoute from "./role.route";
import profileRoute from "./profile.route";
import subscribeRoute from "./subscribe.route";

const routes = [
  { ...homeRoutes },
  { ...authenticateRoute },
  { ...resourceRoute },
  { ...productRoute },
  { ...privilegeRoute },
  { ...postMetaRoute },
  { ...orderRoute },
  { ...contactRoute },
  { ...reviewTagRoute },
  { ...socialRoute },
  { ...productCategoryRoute },
  { ...orderDetailRoute },
  { ...userRoute },
  { ...productPriceRoute },
  { ...emailTemplateRoute },
  { ...emailRecipientRoute },
  { ...userForgotPasswordRoute },
  { ...postRoute },
  { ...bannerRoute },
  { ...emailSenderRoute },
  { ...reviewRoute },
  { ...optionRoute },
  { ...roleRoute },
  { ...profileRoute },
  { ...subscribeRoute },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to) => {
  const publicPages = [
    "/login",
    "/register",
    "/forgot-password",
    "/reset-password",
    "/active",
  ];
  const authRequired = !publicPages.includes(to.path);
  const authStore = useAuthStore();
  if (authRequired && !authStore.user) {
    authStore.returnUrl = to.fullPath;
    return "/login";
  }
});

export default router;
