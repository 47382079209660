<template>
  <!-- Content area -->
  <div class="content">
    <!-- Basic layout -->
    <div class="card">
      <div class="card-header d-flex flex-wrap">
        <h6 class="mb-0">Review</h6>
        <div class="d-inline-flex ms-auto">
          <router-link to="/reviews" class="text-body"
            ><i class="ph-x"></i
          ></router-link>
        </div>
      </div>

      <div class="card-body disable">
        <div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Id:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                v-model="review.id"
                disabled
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Created At:</label>
            <div class="col-lg-9">
              <input type="text" class="form-control created_at" disabled />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Updated At:</label>
            <div class="col-lg-9">
              <input type="text" class="form-control updated_at" disabled />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Name:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                v-model="review.name"
                disabled
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Email:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                v-model="review.email"
                disabled
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Message:</label>
            <div class="col-lg-9">
              <textarea
                rows="3"
                cols="3"
                class="form-control"
                v-model="review.message"
                disabled
              ></textarea>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Type:</label>
            <div class="col-lg-9">
              <select class="form-control type" data-placeholder="‎" disabled>
                <option></option>
                <option v-for="t in types" :key="t.id" :value="t.id">
                  {{ t.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Tags:</label>
            <div class="col-lg-9">
              <select
                multiple="multiple"
                class="form-control tags"
                data-placeholder="‎"
                disabled
              >
                <option></option>
                <option v-for="s in tags" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Status:</label>
            <div class="col-lg-9">
              <select class="form-control status" data-placeholder="‎" disabled>
                <option></option>
                <option v-for="s in statuses" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Photos:</label>
            <div class="col-lg-9">
              <div class="dropzone" id="photos"></div>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Product:</label>
            <div class="col-lg-9">
              <select
                class="form-control product"
                data-placeholder="‎"
                disabled
              >
                <option></option>
                <option v-if="review.product" :value="review.product?.id">
                  {{ review.product?.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Avatar:</label>
            <div class="col-lg-9">
              <div v-if="review.avatar">
                <a :href="API_URL + review.avatar.path" target="_blank">
                  <img
                    :src="API_URL + review.avatar.path"
                    :alt="review.avatar.name"
                    :title="review.avatar.name"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="row mb-3" v-if="false">
            <label class="col-lg-3 col-form-label">Rating:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                v-model="review.rating"
                disabled
              />
            </div>
          </div>
          <div class="text-end" v-if="review.id">
            <button
              type="submit"
              class="btn btn-pink mx-2"
              @click="showConfirmDialog"
            >
              Delete <i class="ph-trash ms-2"></i>
            </button>
            <router-link :to="`/reviews/edit/${id}`" class="btn btn-indigo"
              >Edit <i class="ph-pen ms-2"></i
            ></router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- /basic layout -->
  </div>
  <!-- /content area -->
  <ConfirmDialog ref="dialog" :callback="onDelete" />
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { storeToRefs } from "pinia";
import { useAuthStore, useReviewStore } from "@/stores";
import {
  alert,
  datepicker,
  select,
  selectSearch,
  multiselect,
  dropzone,
} from "@/helpers";
import { API_URL } from "@/const";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

document.title = "Review";

const authStore = useAuthStore();
const reviewStore = useReviewStore();
const route = useRoute();
const id = route.params.id;
const { review, tags, statuses, types } = storeToRefs(reviewStore);
review.value = {};
const dialog = ref();

const result = authStore.getPermission("review");
if (result?.view !== true) {
  router.push("/login");
}

reviewStore.getTags();

async function init() {
  datepicker.init("created_at");
  datepicker.init("updated_at");
  selectSearch.init("product", "products");
  dropzone.initView("photos");
  select.init("type");
  multiselect.init("tags");
  select.init("status");
  if (id) {
    await reviewStore.getById(id);
    datepicker.setValue(
      "created_at",
      review.value.created_at,
      "DD-MM-YYYY HH:mm:ss",
    );
    datepicker.setValue(
      "updated_at",
      review.value.updated_at,
      "DD-MM-YYYY HH:mm:ss",
    );
    selectSearch.setValue("product", review.value.product?.id);
    dropzone.setValue("photos", review.value.photos || []);
    select.setValue("type", review.value.type);
    multiselect.setValue(
      "tags",
      review.value.tags?.map((x) => x.id),
    );
    select.setValue("status", review.value.status);
  }
}

function showConfirmDialog() {
  dialog.value.show("Click OK to delete.");
}
async function onDelete() {
  const result = await reviewStore.delete(id);
  if (result) {
    alert("Delete successfully", "bg-teal");
    router.push("/reviews");
  }
}

onMounted(() => init());
</script>
