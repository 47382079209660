/* eslint-disable no-undef */
export const dateFormat = {
  formatDateString: formatDateString(),
};

function formatDateString() {
  return (dateString, format = "DD-MM-YYYY") => {
    if (dateString) {
      const date = new Date(dateString);
      return moment(date).format(format);
    }
    return "";
  };
}
