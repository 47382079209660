<template>
  <!-- Content area -->
  <div class="content">
    <!-- Basic layout -->
    <div class="card">
      <div class="card-header d-flex flex-wrap">
        <h6 class="mb-0">User</h6>
        <div class="d-inline-flex ms-auto">
          <router-link to="/users" class="text-body"
            ><i class="ph-x"></i
          ></router-link>
        </div>
      </div>

      <div class="card-body disable">
        <div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Id:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                v-model="user.id"
                disabled
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Created At:</label>
            <div class="col-lg-9">
              <input type="text" class="form-control created_at" disabled />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Updated At:</label>
            <div class="col-lg-9">
              <input type="text" class="form-control updated_at" disabled />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Name:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                v-model="user.name"
                disabled
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Phone:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                v-model="user.phone"
                disabled
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Email:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                v-model="user.email"
                disabled
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Avatar:</label>
            <div class="col-lg-9">
              <div v-if="user.avatar">
                <a :href="API_URL + user.avatar.path" target="_blank">
                  <img
                    :src="API_URL + user.avatar.path"
                    :alt="user.avatar.name"
                    :title="user.avatar.name"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Date of Birth:</label>
            <div class="col-lg-9">
              <input type="text" class="form-control dob" disabled />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Address:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                v-model="user.address"
                disabled
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Password:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                v-model="user.password"
                disabled
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Salt:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                v-model="user.salt"
                disabled
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Role:</label>
            <div class="col-lg-9">
              <select class="form-control role" data-placeholder="‎" disabled>
                <option></option>
                <option v-for="s in roles" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3" v-if="false">
            <label class="col-lg-3 col-form-label">Status:</label>
            <div class="col-lg-9">
              <select class="form-control status" data-placeholder="‎" disabled>
                <option></option>
                <option v-for="s in statuses" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="text-end" v-if="user.id">
            <button
              type="submit"
              class="btn btn-pink mx-2"
              @click="showConfirmDialog"
            >
              Delete <i class="ph-trash ms-2"></i>
            </button>
            <router-link :to="`/users/edit/${id}`" class="btn btn-indigo"
              >Edit <i class="ph-pen ms-2"></i
            ></router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- /basic layout -->
  </div>
  <!-- /content area -->
  <ConfirmDialog ref="dialog" :callback="onDelete" />
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { storeToRefs } from "pinia";
import { useAuthStore, useUserStore } from "@/stores";
import { alert, datepicker, select } from "@/helpers";
import { API_URL } from "@/const";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

document.title = "User";

const authStore = useAuthStore();
const userStore = useUserStore();
const route = useRoute();
const id = route.params.id;
const { user, roles, statuses } = storeToRefs(userStore);
user.value = {};
const dialog = ref();

const result = authStore.getPermission("user");
if (result?.view !== true) {
  router.push("/login");
}

userStore.getRoles();

async function init() {
  datepicker.init("created_at");
  datepicker.init("updated_at");
  datepicker.init("dob");
  select.init("role");
  select.init("status");
  if (id) {
    await userStore.getById(id);
    datepicker.setValue(
      "created_at",
      user.value.created_at,
      "DD-MM-YYYY HH:mm:ss",
    );
    datepicker.setValue(
      "updated_at",
      user.value.updated_at,
      "DD-MM-YYYY HH:mm:ss",
    );
    datepicker.setValue("dob", user.value.dob);
    select.setValue("role", user.value.role?.id);
    select.setValue("status", user.value.status);
  }
}

function showConfirmDialog() {
  dialog.value.show("Click OK to delete.");
}
async function onDelete() {
  const result = await userStore.delete(id);
  if (result) {
    alert("Delete successfully", "bg-teal");
    router.push("/users");
  }
}

onMounted(() => init());
</script>
