<template>
  <!-- Content area -->
  <div class="content">
    <!-- Basic layout -->
    <div class="card">
      <div class="card-header d-flex flex-wrap">
        <h6 class="mb-0">My Profile</h6>
      </div>

      <div class="card-body">
        <div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Name:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Name"
                v-model="user.name"
                autocomplete="name"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Phone:</label>
            <div class="col-lg-9">
              <input
                type="phone"
                class="form-control"
                maxlength="255"
                placeholder="Phone"
                v-model="user.phone"
                autocomplete="mobile"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Email:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Email"
                v-model="user.email"
                disabled
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Date of Birth:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control dob"
                placeholder="Date of Birth"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Address:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Address"
                v-model="user.address"
                autocomplete="street-address"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Role:</label>
            <div class="col-lg-9">
              <select
                data-placeholder="Select Role"
                class="form-control"
                disabled
              >
                <option>{{ user.role?.name }}</option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Status:</label>
            <div class="col-lg-9">
              <select
                data-placeholder="Select Status"
                class="form-control status"
                disabled
              >
                <option></option>
                <option v-for="s in statuses" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Avatar:</label>
            <div class="col-lg-9">
              <input
                type="file"
                class="form-control"
                v-on:change="onChangeAvatar($event.target.files[0], 'avatar')"
              />
              <div v-if="user.avatar">
                <a :href="API_URL + user.avatar.path" target="_blank">
                  <img
                    :src="API_URL + user.avatar.path"
                    :alt="user.avatar.name"
                    :title="user.avatar.name"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="text-end">
            <button type="submit" class="btn btn-primary" @click="onSubmit">
              Save <i class="ph-paper-plane-tilt ms-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /basic layout -->
  </div>
  <!-- /content area -->
</template>

<script setup>
import { onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useAuthStore, useProfileStore } from "@/stores";

import { datepicker, select, upload, alert } from "@/helpers";
import { API_URL } from "@/const";

document.title = "My Profile";

const authStore = useAuthStore();
const profileStore = useProfileStore();
const { user, statuses } = storeToRefs(profileStore);

async function init() {
  datepicker.init("dob", (date) => {
    user.value.dob = date;
  });
  select.init("status", (item) => {
    user.value.status = parseInt(item.id);
  });
  await profileStore.getProfile();
  datepicker.setValue("dob", user.value.dob);
  select.setValue("status", user.value.status);
}
function onChangeAvatar(file, type) {
  upload(file, type, (data) => {
    user.value.avatar = data;
  });
}

async function onSubmit() {
  if (!user.value.name) {
    alert("Please enter Name");
    return;
  }
  if (!user.value.phone) {
    alert("Please enter Phone");
    return;
  }
  if (!user.value.email) {
    alert("Please enter Email");
    return;
  }
  if (!user.value.avatar) {
    alert("Please enter Avatar");
    return;
  }
  if (!user.value.dob) {
    alert("Please enter Dob");
    return;
  }
  if (!user.value.address) {
    alert("Please enter Address");
    return;
  }

  const result = await profileStore.updateProfile();
  if (result) {
    authStore.saveUser(user.value);
    alert("Cập nhật thành công", "bg-teal");
  }
}

onMounted(() => init());
</script>
