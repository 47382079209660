import { fetchWrapper, alert } from "@/helpers";
import { paging } from "@/helpers/paging";
import { defineStore } from "pinia";

export const useProductCategoryStore = defineStore("product_category", {
  state: () => ({
    productCategories: {},
    paging: {},
    option: {},
    productCategory: {},
    statuses: [
      { id: 0, name: "Delete" },
      { id: 1, name: "Active" },
    ],
  }),
  actions: {
    async getProductCategories() {
      // this.productCategories = { loading: true };
      try {
        let query = [];
        if (this.option.page) {
          query.push(`page=${this.option.page}`);
        }
        if (this.option.keyword) {
          query.push(`keyword=${this.option.keyword.trim()}`);
        }
        if (this.option.orderBy && this.option.sortBy) {
          query.push(`order_by=${this.option.orderBy}`);
          query.push(`sort_by=${this.option.sortBy}`);
        }

        const result = await fetchWrapper.get(
          `api/product-categories${query.length ? "?" + query.join("&") : ""}`,
        );
        this.productCategories = result.data;

        // paging
        let p = result.paging;
        this.paging = paging(p.page, p.limit, p.total);
      } catch (error) {
        this.productCategories = { error };
      }
    },
    async getById(id) {
      try {
        const result = await fetchWrapper.get(`api/product-categories/${id}`);
        this.productCategory = result.data;
      } catch (error) {
        alert(error);
      }
    },
    async submit() {
      try {
        if (this.productCategory.id) {
          const result = await fetchWrapper.put(
            `api/product-categories/${this.productCategory.id}`,
            this.productCategory,
          );
          return result;
        } else {
          const result = await fetchWrapper.post(
            "api/product-categories",
            this.productCategory,
          );
          return result;
        }
      } catch (error) {
        alert(error);
      }
    },
    async delete(id) {
      try {
        const result = await fetchWrapper.delete(
          `api/product-categories/${id}`,
        );
        return result.data;
      } catch (error) {
        alert(error);
      }
    },
  },
});
