<template>
  <!-- Content area -->
  <div class="content">
    <!-- Basic layout -->
    <div class="card">
      <div class="card-header d-flex flex-wrap">
        <h6 class="mb-0">Change Password</h6>
      </div>

      <form class="card-body" v-on:submit.prevent="">
        <div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Current Password:</label>
            <div class="col-lg-9">
              <input
                type="password"
                class="form-control"
                maxlength="255"
                placeholder="Current Password"
                v-model="user.current_password"
                autocomplete="current-password"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">New Password:</label>
            <div class="col-lg-9">
              <input
                type="password"
                class="form-control"
                maxlength="255"
                placeholder="New Password"
                v-model="user.password"
                autocomplete="new-password"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Confirm New Password:</label>
            <div class="col-lg-9">
              <input
                type="password"
                class="form-control"
                maxlength="255"
                placeholder="Confirm New Password"
                v-model="user.confirm_password"
                autocomplete="new-password"
              />
            </div>
          </div>
          <div class="text-end">
            <button type="submit" class="btn btn-primary" @click="onSubmit">
              Change <i class="ph-paper-plane-tilt ms-2"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
    <!-- /basic layout -->
  </div>
  <!-- /content area -->
</template>

<script setup>
import { reactive } from "vue";
import { useProfileStore } from "@/stores";
import { alert } from "@/helpers";

document.title = "Change Password";

const profileStore = useProfileStore();

const user = reactive({
  current_password: "",
  password: "",
  confirm_password: "",
});

async function onSubmit() {
  if (!user.current_password) {
    alert("Chưa nhập mật khẩu hiện tại");
    return;
  }
  if (!user.password) {
    alert("Chưa nhập mật khẩu mới");
    return;
  }
  if (!user.confirm_password) {
    alert("Chưa nhập lại mật khẩu mới");
    return;
  }
  if (user.password != user.confirm_password) {
    alert("Mật khẩu không trùng khớp");
    return;
  }

  const result = await profileStore.changePassword(
    user.current_password,
    user.password,
  );
  if (result) {
    user.current_password = "";
    user.password = "";
    user.confirm_password = "";
    alert("Đổi mật khẩu thành công", "bg-teal");
  }
}
</script>
