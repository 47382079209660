<template>
  <!-- Content area -->
  <div class="content">
    <!-- Basic layout -->
    <div class="card">
      <div class="card-header d-flex flex-wrap">
        <h6 class="mb-0">Order Detail</h6>
        <div class="d-inline-flex ms-auto">
          <router-link to="/order-details" class="text-body"
            ><i class="ph-x"></i
          ></router-link>
        </div>
      </div>

      <div class="card-body">
        <div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Name:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Name"
                v-model="orderDetail.name"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Quantity:</label>
            <div class="col-lg-9">
              <input
                type="number"
                class="form-control"
                placeholder="Quantity"
                v-model="orderDetail.quantity"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Price:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="None"
                placeholder="Price"
                v-model="price"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Is Sale:</label>
            <div class="col-lg-9">
              <div class="form-check-horizontal">
                <label class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    v-model="orderDetail.is_sale"
                  />
                  <span class="form-check-label">Enable</span>
                </label>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Order:</label>
            <div class="col-lg-9">
              <select
                class="form-control order"
                data-placeholder="Select Order"
              >
                <option></option>
                <option v-if="orderDetail.order" :value="orderDetail.order?.id">
                  {{ orderDetail.order?.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Product:</label>
            <div class="col-lg-9">
              <select
                class="form-control product"
                data-placeholder="Select Product"
              >
                <option></option>
                <option
                  v-if="orderDetail.product"
                  :value="orderDetail.product?.id"
                >
                  {{ orderDetail.product?.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Product Price:</label>
            <div class="col-lg-9">
              <select
                class="form-control product_price"
                data-placeholder="Select Product Price"
              >
                <option></option>
                <option
                  v-if="orderDetail.product_price"
                  :value="orderDetail.product_price?.id"
                >
                  {{ orderDetail.product_price?.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3" v-if="false">
            <label class="col-lg-3 col-form-label">Status:</label>
            <div class="col-lg-9">
              <select
                data-placeholder="Select Status"
                class="form-control status"
              >
                <option></option>
                <option v-for="s in statuses" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="text-end" v-if="orderDetail.id || !id">
            <button type="submit" class="btn btn-primary" @click="onSubmit">
              Save <i class="ph-paper-plane-tilt ms-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /basic layout -->
  </div>
  <!-- /content area -->
</template>

<script setup>
import { onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { storeToRefs } from "pinia";
import { useAuthStore, useOrderDetailStore, useOrderStore } from "@/stores";

import { alert, select, selectSearch, numberFormat } from "@/helpers";

document.title = "Order Detail";

const route = useRoute();
const id = route.params.id;
const authStore = useAuthStore();
const orderDetailStore = useOrderDetailStore();
const orderStore = useOrderStore();
const { orderDetail, statuses } = storeToRefs(orderDetailStore);
const { order } = storeToRefs(orderStore);
orderDetail.value = {};

const result = authStore.getPermission("order_detail");
if ((result?.update !== true && id) || (result?.create !== true && !id)) {
  router.push("/login");
}

let orderId = route.query.order_id;

async function init() {
  selectSearch.init("order", "orders", (item) => {
    orderDetail.value.order = item;
  });
  selectSearch.init("product", "products", (item) => {
    orderDetail.value.product = item;
  });
  selectSearch.init("product_price", "product-prices", (item) => {
    orderDetail.value.product_price = item;
  });
  select.init("status", (item) => {
    orderDetail.value.status = parseInt(item.id);
  });
  if (id) {
    await orderDetailStore.getById(id);
    selectSearch.setValue("order", orderDetail.value.order?.id);
    selectSearch.setValue("product", orderDetail.value.product?.id);
    selectSearch.setValue("product_price", orderDetail.value.product_price?.id);
    select.setValue("status", orderDetail.value.status);
  } else {
    if (orderId) {
      await orderStore.getById(orderId);
      if (order.value.id) {
        orderDetail.value.product = { id: order.value.id };
        selectSearch.setValue("order", orderDetail.value.order?.id);
      }
    }
  }
}

async function onSubmit() {
  if (!orderDetail.value.name) {
    alert("Please enter Name");
    return;
  }
  if (!orderDetail.value.quantity) {
    alert("Please enter Quantity");
    return;
  }
  if (!orderDetail.value.price) {
    alert("Please enter Price");
    return;
  }
  if (!orderDetail.value.order) {
    alert("Please enter Order");
    return;
  }
  if (!orderDetail.value.product) {
    alert("Please enter Product");
    return;
  }
  if (!orderDetail.value.product_price) {
    alert("Please enter Product Price");
    return;
  }
  // if (!orderDetail.value.status) {
  //   alert("Please enter Status");
  //   return;
  // }

  const result = await orderDetailStore.submit();
  if (result) {
    if (orderId) {
      router.push(`/order-details?order_id=${orderDetail.value.order?.id}`);
    } else {
      router.back();
    }
  }
}

onMounted(() => init());

const price = computed({
  get() {
    return numberFormat.format(orderDetail.value.price);
  },
  set(newValue) {
    orderDetail.value.price = numberFormat.getNumber(newValue);
  },
});
</script>
