/* eslint-disable no-undef */
// For Vietnamese

export const numberFormat = {
  formatPrice: formatPrice(),
  format: format(),
  getNumber: getNumber(),
};

export function formatNumber(n) {
  // format number 1000000 to 1,234,567
  return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function formatPrice() {
  return (n) => {
    if (!n) return "-";
    return new Intl.NumberFormat("vi-VN", {
      style: "currency",
      currency: "VND",
    }).format(n);
  };
}

function format() {
  return (input) => {
    if (!input) return "";

    // Việt Nam phân cách hàng nghìn bằng dấu .
    // Việt Nam phân cách hàng thập phân bằng dấu ,

    input = String(input).replace(".", ",");

    // check for decimal
    var decimal_pos = input.indexOf(",");

    if (decimal_pos >= 0) {
      // split number by decimal point
      var left_side = input.substring(0, decimal_pos);
      var right_side = input.substring(decimal_pos);

      // add commas to left side of number
      left_side = formatNumber(left_side);

      // validate right side
      right_side = formatNumber(right_side);

      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 2).replace(".", "");

      // join number by .
      input = left_side + "," + right_side;
    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input = formatNumber(input);
    }

    return input;
  };
}

function getNumber() {
  return (input) => {
    if (!input) return 0;

    input = input.replaceAll(".", "").replace(",", ".");

    console.log(input);
    return parseFloat(input);
  };
}
