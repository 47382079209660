import { Layout } from "@/views";
import { List, AddEdit, View } from "@/views/product_prices";

export default {
  path: "/product-prices",
  component: Layout,
  children: [
    { path: "", component: List },
    { path: "add", component: AddEdit },
    { path: "edit/:id", component: AddEdit },
    { path: ":id", component: View },
  ],
};
