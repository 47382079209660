<template>
  <!-- Content area -->
  <div class="content">
    <!-- Basic layout -->
    <div class="card">
      <div class="card-header d-flex flex-wrap">
        <h6 class="mb-0">Review</h6>
        <div class="d-inline-flex ms-auto">
          <router-link to="/reviews" class="text-body"
            ><i class="ph-x"></i
          ></router-link>
        </div>
      </div>

      <div class="card-body">
        <div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Created at:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control created_at"
                placeholder="Created at"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Name:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Name"
                v-model="review.name"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Email:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Email"
                v-model="review.email"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Message:</label>
            <div class="col-lg-9">
              <textarea
                rows="3"
                cols="3"
                class="form-control"
                placeholder="Message"
                v-model="review.message"
              ></textarea>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Type:</label>
            <div class="col-lg-9">
              <select data-placeholder="Select Type" class="form-control type">
                <option></option>
                <option v-for="t in types" :key="t.id" :value="t.id">
                  {{ t.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Tags:</label>
            <div class="col-lg-9">
              <select
                data-placeholder="Select Tags"
                multiple="multiple"
                class="form-control tags"
              >
                <option></option>
                <option v-for="s in tags" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Status:</label>
            <div class="col-lg-9">
              <select
                data-placeholder="Select Status"
                class="form-control status"
              >
                <option></option>
                <option v-for="s in statuses" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Photos:</label>
            <div class="col-lg-9">
              <div class="dropzone" id="photos"></div>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Product:</label>
            <div class="col-lg-9">
              <select
                class="form-control product"
                data-placeholder="Select Product"
              >
                <option></option>
                <option v-if="review.product" :value="review.product?.id">
                  {{ review.product?.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Avatar:</label>
            <div class="col-lg-9">
              <input
                type="file"
                class="form-control"
                v-on:change="onChangeAvatar($event.target.files[0], 'avatar')"
              />
              <div class="form-text text-muted" v-if="!review.avatar">
                Accepted formats: png, jpg. Max file size 5Mb
              </div>
              <div v-if="review.avatar">
                <a :href="API_URL + review.avatar.path" target="_blank">
                  <img
                    :src="API_URL + review.avatar.path"
                    :alt="review.avatar.name"
                    :title="review.avatar.name"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="row mb-3" v-if="false">
            <label class="col-lg-3 col-form-label">Rating:</label>
            <div class="col-lg-9">
              <input
                type="number"
                class="form-control"
                placeholder="Rating"
                v-model="review.rating"
              />
            </div>
          </div>
          <div class="text-end" v-if="review.id || !id">
            <button type="submit" class="btn btn-primary" @click="onSubmit">
              Save <i class="ph-paper-plane-tilt ms-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /basic layout -->
  </div>
  <!-- /content area -->
</template>

<script setup>
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { storeToRefs } from "pinia";
import { useAuthStore, useReviewStore } from "@/stores";

import {
  datepicker,
  select,
  multiselect,
  selectSearch,
  upload,
  dropzone,
} from "@/helpers";
import { API_URL } from "@/const";

document.title = "Review";

const route = useRoute();
const id = route.params.id;
const authStore = useAuthStore();
const reviewStore = useReviewStore();
const { review, tags, statuses, types } = storeToRefs(reviewStore);
review.value = {};

const result = authStore.getPermission("review");
if ((result?.update !== true && id) || (result?.create !== true && !id)) {
  router.push("/login");
}

reviewStore.getTags();

async function init() {
  datepicker.init("created_at", (date) => {
    review.value.created_at = date;
  });
  selectSearch.init("product", "products", (item) => {
    review.value.product = item;
  });
  dropzone.init("photos", "reviews/photos", (items) => {
    review.value.photos = items;
  });
  select.init("type", (item) => {
    review.value.type = parseInt(item.id);
  });
  multiselect.init("tags", (items) => {
    review.value.tags = items;
  });
  select.init("status", (item) => {
    review.value.status = parseInt(item.id);
  });
  if (id) {
    await reviewStore.getById(id);
    datepicker.setValue("created_at", review.value.created_at);
    selectSearch.setValue("product", review.value.product?.id);
    dropzone.setValue("photos", review.value.photos || []);
    select.setValue("type", review.value.type);
    multiselect.setValue(
      "tags",
      review.value.tags?.map((x) => x.id),
    );
    select.setValue("status", review.value.status);
  }
}
function onChangeAvatar(file, type) {
  upload(file, type, (data) => {
    review.value.avatar = data;
  });
}

async function onSubmit() {
  // if (!review.value.name) {
  //   alert("Please enter Name");
  //   return;
  // }
  // if (!review.value.email) {
  //   alert("Please enter Email");
  //   return;
  // }
  // if (!review.value.avatar) {
  //   alert("Please enter Avatar");
  //   return;
  // }
  // if (!review.value.message) {
  //   alert("Please enter Message");
  //   return;
  // }
  // if (!review.value.rating) {
  //   alert("Please enter Rating");
  //   return;
  // }
  // if (!review.value.product) {
  //   alert("Please enter Product");
  //   return;
  // }
  // if (!review.value.photos) {
  //   alert("Please enter Photos");
  //   return;
  // }
  // if (!review.value.type) {
  //   alert("Please enter Type");
  //   return;
  // }
  // if (!review.value.status) {
  //   alert("Please enter Status");
  //   return;
  // }

  const result = await reviewStore.submit();
  if (result) {
    router.push("/reviews");
    // router.back();
  }
}

onMounted(() => init());
</script>
