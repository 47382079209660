<template>
  <!-- Content area -->
  <div class="content">
    <!-- Basic layout -->
    <div class="card">
      <div class="card-header d-flex flex-wrap">
        <h6 class="mb-0">Email Sender</h6>
        <div class="d-inline-flex ms-auto">
          <router-link to="/email-senders" class="text-body"
            ><i class="ph-x"></i
          ></router-link>
        </div>
      </div>

      <div class="card-body">
        <div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Name:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Name"
                v-model="emailSender.name"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Email:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Email"
                v-model="emailSender.email"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Password:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Password"
                v-model="emailSender.password"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">SMTP Host:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="31"
                placeholder="SMTP Host"
                v-model="emailSender.smtp_host"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">SMTP Port:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="3"
                placeholder="SMTP Port"
                v-model="emailSender.smtp_port"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Code:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Code"
                v-model="emailSender.code"
              />
            </div>
          </div>
          <div class="row mb-3" v-if="false">
            <label class="col-lg-3 col-form-label">Status:</label>
            <div class="col-lg-9">
              <select
                data-placeholder="Select Status"
                class="form-control status"
              >
                <option></option>
                <option v-for="s in statuses" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="text-end" v-if="emailSender.id || !id">
            <button type="submit" class="btn btn-primary" @click="onSubmit">
              Save <i class="ph-paper-plane-tilt ms-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /basic layout -->
  </div>
  <!-- /content area -->
</template>

<script setup>
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { storeToRefs } from "pinia";
import { useAuthStore, useEmailSenderStore } from "@/stores";

import { alert, select } from "@/helpers";

document.title = "Email Sender";

const route = useRoute();
const id = route.params.id;
const authStore = useAuthStore();
const emailSenderStore = useEmailSenderStore();
const { emailSender, statuses } = storeToRefs(emailSenderStore);
emailSender.value = {};

const result = authStore.getPermission("email_sender");
if ((result?.update !== true && id) || (result?.create !== true && !id)) {
  router.push("/login");
}

async function init() {
  select.init("status", (item) => {
    emailSender.value.status = parseInt(item.id);
  });
  if (id) {
    await emailSenderStore.getById(id);
    select.setValue("status", emailSender.value.status);
  }
}

async function onSubmit() {
  // if (!emailSender.value.name) {
  //   alert("Please enter Name");
  //   return;
  // }
  if (!emailSender.value.email) {
    alert("Please enter Email");
    return;
  }
  if (!emailSender.value.password) {
    alert("Please enter Password");
    return;
  }
  if (!emailSender.value.smtp_host) {
    alert("Please enter Smtp Host");
    return;
  }
  if (!emailSender.value.smtp_port) {
    alert("Please enter Smtp Port");
    return;
  }
  // if (!emailSender.value.code) {
  //   alert("Please enter Code");
  //   return;
  // }
  // if (!emailSender.value.status) {
  //   alert("Please enter Status");
  //   return;
  // }

  const result = await emailSenderStore.submit();
  if (result) {
    // router.push("/email-senders");
    router.back();
  }
}

onMounted(() => init());
</script>
