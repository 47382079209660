export function paging(page, limit, total) {
  let pages = [];
  let maxPage = Math.ceil(total / limit);
  let e = Math.floor(page / 10);

  for (let i = 1; i <= 10; i++) {
    let p = i + e * 10;
    if (p <= maxPage) {
      pages.push(p);
    }
  }

  if (pages.length <= 1) {
    return {};
  }

  if (!pages.includes(1)) {
    pages.unshift(1);
  }

  if (!pages.includes(maxPage)) {
    pages.push(maxPage);
  }

  let older = page - 1;
  if (older <= 0) {
    older = null;
  }

  let newer = page + 1;
  if (newer > maxPage) {
    newer = null;
  }

  return {
    pages: pages,
    page: page,
    maxPage: maxPage,
    older: older,
    newer: newer,
  };
}
