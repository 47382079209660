<template>
  <!-- Content area -->
  <div class="content">
    <!-- Basic layout -->
    <div class="card">
      <div class="card-header d-flex flex-wrap">
        <h6 class="mb-0">Order Detail</h6>
        <div class="d-inline-flex ms-auto">
          <router-link to="/order-details" class="text-body"
            ><i class="ph-x"></i
          ></router-link>
        </div>
      </div>

      <div class="card-body disable">
        <div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Id:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                v-model="orderDetail.id"
                disabled
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Created At:</label>
            <div class="col-lg-9">
              <input type="text" class="form-control created_at" disabled />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Updated At:</label>
            <div class="col-lg-9">
              <input type="text" class="form-control updated_at" disabled />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Name:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                v-model="orderDetail.name"
                disabled
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Quantity:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                v-model="orderDetail.quantity"
                disabled
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Price:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                v-model="orderDetail.price"
                disabled
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Is Sale:</label>
            <div class="col-lg-9">
              <div class="form-check-horizontal">
                <label class="form-check form-check-inline">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    v-model="orderDetail.is_sale"
                    disabled
                  />
                  <span class="form-check-label">Enable</span>
                </label>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Order:</label>
            <div class="col-lg-9">
              <select class="form-control order" data-placeholder="‎" disabled>
                <option></option>
                <option v-if="orderDetail.order" :value="orderDetail.order?.id">
                  {{ orderDetail.order?.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Product:</label>
            <div class="col-lg-9">
              <select
                class="form-control product"
                data-placeholder="‎"
                disabled
              >
                <option></option>
                <option
                  v-if="orderDetail.product"
                  :value="orderDetail.product?.id"
                >
                  {{ orderDetail.product?.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Product Price:</label>
            <div class="col-lg-9">
              <select
                class="form-control product_price"
                data-placeholder="‎"
                disabled
              >
                <option></option>
                <option
                  v-if="orderDetail.product_price"
                  :value="orderDetail.product_price?.id"
                >
                  {{ orderDetail.product_price?.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3" v-if="false">
            <label class="col-lg-3 col-form-label">Status:</label>
            <div class="col-lg-9">
              <select class="form-control status" data-placeholder="‎" disabled>
                <option></option>
                <option v-for="s in statuses" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="text-end" v-if="orderDetail.id">
            <button
              type="submit"
              class="btn btn-pink mx-2"
              @click="showConfirmDialog"
            >
              Delete <i class="ph-trash ms-2"></i>
            </button>
            <router-link
              :to="`/order-details/edit/${id}`"
              class="btn btn-indigo"
              >Edit <i class="ph-pen ms-2"></i
            ></router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- /basic layout -->
  </div>
  <!-- /content area -->
  <ConfirmDialog ref="dialog" :callback="onDelete" />
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { storeToRefs } from "pinia";
import { useAuthStore, useOrderDetailStore } from "@/stores";
import { alert, datepicker, select, selectSearch } from "@/helpers";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

document.title = "Order Detail";

const authStore = useAuthStore();
const orderDetailStore = useOrderDetailStore();
const route = useRoute();
const id = route.params.id;
const { orderDetail, statuses } = storeToRefs(orderDetailStore);
orderDetail.value = {};
const dialog = ref();

const result = authStore.getPermission("order_detail");
if (result?.view !== true) {
  router.push("/login");
}

async function init() {
  datepicker.init("created_at");
  datepicker.init("updated_at");
  selectSearch.init("order", "orders");
  selectSearch.init("product", "products");
  selectSearch.init("product_price", "product-prices");
  select.init("status");
  if (id) {
    await orderDetailStore.getById(id);
    datepicker.setValue(
      "created_at",
      orderDetail.value.created_at,
      "DD-MM-YYYY HH:mm:ss",
    );
    datepicker.setValue(
      "updated_at",
      orderDetail.value.updated_at,
      "DD-MM-YYYY HH:mm:ss",
    );
    selectSearch.setValue("order", orderDetail.value.order?.id);
    selectSearch.setValue("product", orderDetail.value.product?.id);
    selectSearch.setValue("product_price", orderDetail.value.product_price?.id);
    select.setValue("status", orderDetail.value.status);
  }
}

function showConfirmDialog() {
  dialog.value.show("Click OK to delete.");
}
async function onDelete() {
  const result = await orderDetailStore.delete(id);
  if (result) {
    alert("Delete successfully", "bg-teal");
    router.push("/order-details");
  }
}

onMounted(() => init());
</script>
