<!-- eslint-disable prettier/prettier -->
<template>
  <!-- Login form -->
  <form
    id="needs-validation"
    class="login-form"
    novalidate
    v-on:submit.prevent=""
  >
    <div class="card mb-0">
      <div class="card-body">
        <div class="text-center mb-3">
          <div
            class="d-inline-flex align-items-center justify-content-center mb-4 mt-2"
          >
            <img src="/assets/images/logo_icon.svg" class="h-48px" alt="" />
          </div>
          <h5 class="mb-0">Forgot Password</h5>
          <span class="d-block text-muted"
            >If you have forgotten your password, you can use this form to reset
            your password. You will receive an email with instructions.</span
          >
        </div>

        <div class="mb-3">
          <label class="form-label">Email</label>
          <div class="form-control-feedback form-control-feedback-start">
            <input
              type="email"
              class="form-control"
              placeholder="hieutao@live.com"
              v-model="user.email"
              autocomplete="username"
              required
            />
            <div class="form-control-feedback-icon">
              <i class="ph-at text-muted"></i>
            </div>
            <div class="invalid-feedback">Invalid email</div>
          </div>
        </div>

        <div class="mb-3">
          <button type="submit" class="btn btn-primary w-100">
            Send Email
          </button>
        </div>

        <div class="text-center text-muted content-divider mb-3">
          <span class="px-2">OR</span>
        </div>
        <div class="row">
          <div class="col">
            <router-link to="/register" class="btn btn-light w-100"
              >Sign up</router-link
            >
          </div>
          <div class="col">
            <router-link to="/login" class="btn btn-primary w-100"
              >Sign in</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </form>
  <!-- /login form -->
</template>

<script setup>
import { reactive, onMounted } from "vue";
import { useAuthStore } from "@/stores";
import { formValidation, alert } from "@/helpers";

document.title = "Forgot Password";

const user = reactive({
  email: "",
});

const authStore = useAuthStore();
authStore.logout();

onMounted(() =>
  formValidation(async () => {
    const result = await authStore.forgotPassword(user.email);
    if (result) {
      alert("Gửi thành công, vui lòng kiểm tra email", "bg-teal");
      var form = document.getElementById("needs-validation");
      form.classList.remove("was-validated");
      user.email = "";
    }
  }),
);
</script>
