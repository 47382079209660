/* eslint-disable */

export const multiselect = {
  init: init(),
  setValue: setValue(),
};

function init() {
  return (name, onChange) => {
    $("." + name)
      .select2({
        dropdownParent: $("." + name).parent(),
        minimumResultsForSearch: Infinity,
      })
      .on("select2:select select2:unselect", function () {
        const items = $(this)
          .select2("data")
          .map((x) => {
            return { id: x.id, name: x.text };
          });
        onChange(items);
      });
  };
}

function setValue() {
  return (name, value) => {
    if (value) {
      $("." + name)
        .val(value || [])
        .trigger("change");
    }
  };
}
