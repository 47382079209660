import { fetchWrapper, alert } from "@/helpers";
import { paging } from "@/helpers/paging";
import { defineStore } from "pinia";

export const useOptionStore = defineStore("option", {
  state: () => ({
    options: {},
    paging: {},
    option: {},
    statuses: [
      { id: 0, name: "Delete" },
      { id: 1, name: "Active" },
    ],
  }),
  actions: {
    async getOptions() {
      // this.options = { loading: true };
      try {
        let query = [];
        if (this.option.page) {
          query.push(`page=${this.option.page}`);
        }
        if (this.option.keyword) {
          query.push(`keyword=${this.option.keyword.trim()}`);
        }
        if (this.option.orderBy && this.option.sortBy) {
          query.push(`order_by=${this.option.orderBy}`);
          query.push(`sort_by=${this.option.sortBy}`);
        }

        const result = await fetchWrapper.get(
          `api/options${query.length ? "?" + query.join("&") : ""}`,
        );
        this.options = result.data;

        // paging
        let p = result.paging;
        this.paging = paging(p.page, p.limit, p.total);
      } catch (error) {
        this.options = { error };
      }
    },
    async getById(id) {
      try {
        const result = await fetchWrapper.get(`api/options/${id}`);
        this.option = result.data;
      } catch (error) {
        alert(error);
      }
    },
    async submit() {
      try {
        if (this.option.id) {
          const result = await fetchWrapper.put(
            `api/options/${this.option.id}`,
            this.option,
          );
          return result;
        } else {
          const result = await fetchWrapper.post("api/options", this.option);
          return result;
        }
      } catch (error) {
        alert(error);
      }
    },
    async delete(id) {
      try {
        const result = await fetchWrapper.delete(`api/options/${id}`);
        return result.data;
      } catch (error) {
        alert(error);
      }
    },
  },
});
