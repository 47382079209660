import { Layout } from "@/views";
import { Profile, ChangePassword } from "@/views/users";

export default {
  path: "/",
  component: Layout,
  children: [
    { path: "profile", component: Profile },
    { path: "change-password", component: ChangePassword },
  ],
};
