<template>
  <!-- Content area -->
  <div class="content">
    <!-- Basic layout -->
    <div class="card">
      <div class="card-header d-flex flex-wrap">
        <h6 class="mb-0">Option</h6>
        <div class="d-inline-flex ms-auto">
          <router-link to="/options" class="text-body"
            ><i class="ph-x"></i
          ></router-link>
        </div>
      </div>

      <div class="card-body">
        <div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Code:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Code"
                v-model="option.code"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Name:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Name"
                v-model="option.name"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Value:</label>
            <div class="col-lg-9">
              <ckeditor
                class="form-control"
                :editor="editorValue"
                v-model="option.value"
                :config="editorValueConfig"
              ></ckeditor>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Photo:</label>
            <div class="col-lg-9">
              <input
                type="file"
                class="form-control"
                v-on:change="onChangePhoto($event.target.files[0], 'photo')"
              />
              <div class="form-text text-muted" v-if="!option.photo">
                Accepted formats: png, jpg. Max file size 5Mb
              </div>
              <div v-if="option.photo">
                <a :href="API_URL + option.photo.path" target="_blank">
                  <img
                    :src="API_URL + option.photo.path"
                    :alt="option.photo.name"
                    :title="option.photo.name"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="row mb-3" v-if="false">
            <label class="col-lg-3 col-form-label">Status:</label>
            <div class="col-lg-9">
              <select
                data-placeholder="Select Status"
                class="form-control status"
              >
                <option></option>
                <option v-for="s in statuses" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="text-end" v-if="option.id || !id">
            <button type="submit" class="btn btn-primary" @click="onSubmit">
              Save <i class="ph-paper-plane-tilt ms-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /basic layout -->
  </div>
  <!-- /content area -->
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { storeToRefs } from "pinia";
import { useAuthStore, useOptionStore } from "@/stores";

import { alert, select, upload, MyUploadAdapter } from "@/helpers";
import { API_URL } from "@/const";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

document.title = "Option";

const route = useRoute();
const id = route.params.id;
const authStore = useAuthStore();
const optionStore = useOptionStore();
const { option, statuses } = storeToRefs(optionStore);
option.value = {};

const editorValue = ref(ClassicEditor);
const editorValueConfig = ref({
  mediaEmbed: { previewsInData: true },
  extraPlugins: [
    function (editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
      };
    },
  ],
});

const result = authStore.getPermission("option");
if ((result?.update !== true && id) || (result?.create !== true && !id)) {
  router.push("/login");
}

async function init() {
  select.init("status", (item) => {
    option.value.status = parseInt(item.id);
  });
  if (id) {
    await optionStore.getById(id);
    select.setValue("status", option.value.status);
  }
}
function onChangePhoto(file, type) {
  upload(file, `options/${type}`, (data) => {
    option.value.photo = data;
  });
}

async function onSubmit() {
  if (!option.value.code) {
    alert("Please enter Code");
    return;
  }
  // if (!option.value.name) {
  //   alert("Please enter Name");
  //   return;
  // }
  // if (!option.value.value) {
  //   alert("Please enter Value");
  //   return;
  // }
  // if (!option.value.photo) {
  //   alert("Please enter Photo");
  //   return;
  // }
  // if (!option.value.status) {
  //   alert("Please enter Status");
  //   return;
  // }

  const result = await optionStore.submit();
  if (result) {
    // router.push("/options");
    router.back();
  }
}

onMounted(() => init());
</script>
