/* eslint-disable */

export const select = {
  init: init(),
  setValue: setValue(),
};

function init() {
  return (name, onChange) => {
    $("." + name)
      .select2({
        dropdownParent: $("." + name).parent(),
        minimumResultsForSearch: Infinity,
      })
      .on("select2:select", function (e) {
        const data = e.params.data;
        onChange({ id: data.id, name: data.text });
      });
  };
}

function setValue() {
  return (name, value) => {
    if (value) {
      $("." + name)
        .val(value || "")
        .trigger("change");
    }
  };
}
