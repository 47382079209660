<template>
  <ul
    :class="{
      'nav nav-sidebar': isFirst,
      'nav-group-sub collapse': !isFirst,
    }"
  >
    <li
      :class="{
        'nav-item': true,
        'nav-item-submenu': authStore.getMenus(m.id).length > 0,
      }"
      v-for="m in menu"
      :key="m.id"
    >
      <router-link
        :to="m.link || '/'"
        @click="active = m.link"
        :class="{
          'nav-link': true,
          active: active === m.link,
        }"
      >
        <i v-if="isFirst" :class="m.icon || 'ph-signpost'"></i>
        <span>
          {{ m.name }}
        </span>
      </router-link>
      <MenuLevel v-if="authStore.getMenus(m.id).length > 0" :parentId="m.id" />
    </li>
  </ul>
</template>

<script>
import { useAuthStore } from "@/stores/auth.store";
import MenuLevel from "@/components/MenuLevel.vue";
import { ref, toRefs } from "vue";
import router from "@/router";

export default {
  props: ["parentId"],
  setup(props) {
    const { parentId } = toRefs(props);

    const authStore = useAuthStore();
    const menu = authStore.getMenus(parentId.value);
    const isFirst = parentId.value === undefined;

    const active = ref(router.currentRoute.value.fullPath);

    return {
      active,
      isFirst,
      menu,
      authStore,
      MenuLevel,
    };
  },
};
</script>
