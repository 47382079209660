<!-- eslint-disable prettier/prettier -->
<template>
  <!-- Login form -->
  <form
    id="needs-validation"
    class="login-form"
    novalidate
    v-on:submit.prevent=""
  >
    <div class="card mb-0">
      <div class="card-body">
        <div class="text-center mb-3">
          <div
            class="d-inline-flex align-items-center justify-content-center mb-4 mt-2"
          >
            <img src="/assets/images/logo_icon.svg" class="h-48px" alt="" />
          </div>
          <h5 class="mb-0">Create account</h5>
          <span class="d-block text-muted">Enter your credentials below</span>
        </div>

        <div class="mb-3">
          <label class="form-label">Name</label>
          <div class="form-control-feedback form-control-feedback-start">
            <input
              type="text"
              class="form-control"
              placeholder="Hieu Tao"
              v-model="user.name"
              autocomplete="name"
              required
            />
            <div class="form-control-feedback-icon">
              <i class="ph-user-circle text-muted"></i>
            </div>
            <div class="invalid-feedback">Invalid email</div>
          </div>
        </div>

        <div class="mb-3">
          <label class="form-label">Email</label>
          <div class="form-control-feedback form-control-feedback-start">
            <input
              type="email"
              class="form-control"
              placeholder="hieutao@live.com"
              v-model="user.email"
              autocomplete="username"
              required
            />
            <div class="form-control-feedback-icon">
              <i class="ph-at text-muted"></i>
            </div>
            <div class="invalid-feedback">Invalid email</div>
          </div>
        </div>

        <div class="mb-3">
          <label class="form-label">Password</label>
          <div class="form-control-feedback form-control-feedback-start">
            <input
              type="password"
              class="form-control"
              placeholder="•••••••••••"
              v-model="user.password"
              autocomplete="new-password"
              required
            />
            <div class="form-control-feedback-icon">
              <i class="ph-lock text-muted"></i>
            </div>
            <div class="invalid-feedback">Invalid password</div>
          </div>
        </div>

        <div class="mb-3">
          <label class="form-label">Confirm Password</label>
          <div class="form-control-feedback form-control-feedback-start">
            <input
              type="password"
              class="form-control"
              placeholder="•••••••••••"
              v-model="user.confirm_password"
              autocomplete="new-password"
              required
            />
            <div class="form-control-feedback-icon">
              <i class="ph-lock text-muted"></i>
            </div>
            <div class="invalid-feedback">Invalid password</div>
          </div>
        </div>

        <div class="d-flex align-items-center mb-3">
          <label class="form-check">
            <input
              type="checkbox"
              name="remember"
              class="form-check-input"
              required
            />
            <span class="form-check-label"
              >Accept <a href="#">&nbsp;terms of service</a></span
            >
          </label>
        </div>

        <div class="mb-3">
          <button type="submit" class="btn btn-primary w-100">Sign up</button>
        </div>

        <div class="text-center text-muted content-divider mb-3">
          <span class="px-2">Have an account?</span>
        </div>

        <div class="mb-3">
          <router-link to="/login" class="btn btn-light w-100"
            >Sign in</router-link
          >
        </div>
      </div>
    </div>
  </form>
  <!-- /login form -->
</template>

<script setup>
import { reactive, onMounted } from "vue";
import { useAuthStore } from "@/stores";
import { formValidation, alert } from "@/helpers";
import router from "@/router";

document.title = "Sign up";

const user = reactive({
  name: "",
  email: "",
  password: "",
  confirm_password: "",
});

const authStore = useAuthStore();
authStore.logout();

onMounted(() =>
  formValidation(async () => {
    if (user.password != user.confirm_password) {
      alert("Mật khẩu không trùng khớp");
      return;
    }
    const result = await authStore.register(
      user.name,
      user.email,
      user.password,
    );
    if (result) {
      alert("Vào email để kích hoạt", "bg-teal");
      router.push("/login");
    }
  }),
);
</script>
