<template>
  <!-- Content area -->
  <div class="content">
    <!-- Basic layout -->
    <div class="card">
      <div class="card-header d-flex flex-wrap">
        <h6 class="mb-0">User</h6>
        <div class="d-inline-flex ms-auto">
          <router-link to="/users" class="text-body"
            ><i class="ph-x"></i
          ></router-link>
        </div>
      </div>

      <div class="card-body">
        <div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Name:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Name"
                v-model="user.name"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Phone:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Phone"
                v-model="user.phone"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Email:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Email"
                v-model="user.email"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Avatar:</label>
            <div class="col-lg-9">
              <input
                type="file"
                class="form-control"
                v-on:change="onChangeAvatar($event.target.files[0], 'avatar')"
              />
              <div class="form-text text-muted" v-if="!user.avatar">
                Accepted formats: png, jpg. Max file size 5Mb
              </div>
              <div v-if="user.avatar">
                <a :href="API_URL + user.avatar.path" target="_blank">
                  <img
                    :src="API_URL + user.avatar.path"
                    :alt="user.avatar.name"
                    :title="user.avatar.name"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Date of Birth:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control dob"
                placeholder="Date of Birth"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Address:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Address"
                v-model="user.address"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Password:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Password"
                v-model="user.password"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Salt:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Salt"
                v-model="user.salt"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Role:</label>
            <div class="col-lg-9">
              <select data-placeholder="Select Role" class="form-control role">
                <option></option>
                <option v-for="s in roles" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3" v-if="false">
            <label class="col-lg-3 col-form-label">Status:</label>
            <div class="col-lg-9">
              <select
                data-placeholder="Select Status"
                class="form-control status"
              >
                <option></option>
                <option v-for="s in statuses" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="text-end" v-if="user.id || !id">
            <button type="submit" class="btn btn-primary" @click="onSubmit">
              Save <i class="ph-paper-plane-tilt ms-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /basic layout -->
  </div>
  <!-- /content area -->
</template>

<script setup>
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { storeToRefs } from "pinia";
import { useAuthStore, useUserStore } from "@/stores";

import { alert, datepicker, select, upload } from "@/helpers";
import { API_URL } from "@/const";

document.title = "User";

const route = useRoute();
const id = route.params.id;
const authStore = useAuthStore();
const userStore = useUserStore();
const { user, roles, statuses } = storeToRefs(userStore);
user.value = {};

const result = authStore.getPermission("user");
if ((result?.update !== true && id) || (result?.create !== true && !id)) {
  router.push("/login");
}

userStore.getRoles();

async function init() {
  datepicker.init("dob", (date) => {
    user.value.dob = date;
  });
  select.init("role", (item) => {
    user.value.role = item;
  });
  select.init("status", (item) => {
    user.value.status = parseInt(item.id);
  });
  if (id) {
    await userStore.getById(id);
    datepicker.setValue("dob", user.value.dob);
    select.setValue("role", user.value.role?.id);
    select.setValue("status", user.value.status);
  }
}
function onChangeAvatar(file, type) {
  upload(file, type, (data) => {
    user.value.avatar = data;
  });
}

async function onSubmit() {
  if (!user.value.name) {
    alert("Please enter Name");
    return;
  }
  // if (!user.value.phone) {
  //   alert("Please enter Phone");
  //   return;
  // }
  if (!user.value.email) {
    alert("Please enter Email");
    return;
  }
  // if (!user.value.avatar) {
  //   alert("Please enter Avatar");
  //   return;
  // }
  // if (!user.value.dob) {
  //   alert("Please enter Dob");
  //   return;
  // }
  // if (!user.value.address) {
  //   alert("Please enter Address");
  //   return;
  // }
  // if (!user.value.password) {
  //   alert("Please enter Password");
  //   return;
  // }
  // if (!user.value.salt) {
  //   alert("Please enter Salt");
  //   return;
  // }
  // if (!user.value.role) {
  //   alert("Please enter Role");
  //   return;
  // }
  // if (!user.value.status) {
  //   alert("Please enter Status");
  //   return;
  // }

  const result = await userStore.submit();
  if (result) {
    // router.push("/users");
    router.back();
  }
}

onMounted(() => init());
</script>
