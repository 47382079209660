<template>
  <!-- Content area -->
  <div class="content">
    <!-- table borders -->
    <div class="card" v-if="permission?.view">
      <div class="card-header d-lg-flex">
        <div class="d-flex">
          <h4 class="mb-0">Product Category</h4>
          <a
            href="#page_header"
            class="btn btn-light align-self-center collapsed d-lg-none border-transparent rounded-pill p-0 ms-auto"
            data-bs-toggle="collapse"
          >
            <i class="ph-caret-down collapsible-indicator ph-sm m-1"></i>
          </a>
        </div>

        <div class="collapse d-lg-block my-lg-auto ms-lg-auto" id="page_header">
          <div class="d-sm-flex align-items-center mb-3 mb-lg-0 ms-lg-3">
            <div
              class="form-control-feedback form-control-feedback-end flex-fill"
            >
              <input
                type="text"
                class="form-control"
                placeholder="Search"
                v-model="option.keyword"
                v-on:keyup.esc="onEsc"
                v-on:keyup.enter="onEnter"
              />
              <div class="form-control-feedback-icon">
                <i class="ph-magnifying-glass opacity-50"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th>
                <a href="#" @click="orderBy('name')">
                  Name
                  <span
                    v-if="option.orderBy === 'name' && option.sortBy === 'asc'"
                    >&darr;</span
                  >
                  <span
                    v-if="option.orderBy === 'name' && option.sortBy == 'desc'"
                    >&uarr;</span
                  >
                </a>
              </th>
              <th class="text-center w125">
                <router-link
                  to="/product-categories/add"
                  class="text-primary"
                  v-if="permission?.create"
                >
                  <i
                    class="ph-plus-circle"
                    data-bs-popup="tooltip"
                    title="Add"
                  ></i>
                </router-link>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="productCategories.length">
              <tr
                v-for="productCategory in productCategories"
                :key="productCategory.id"
              >
                <td>{{ productCategory.name }}</td>
                <td class="text-center">
                  <router-link
                    :to="`/product-categories/${productCategory.id}`"
                    class="text-info me-1"
                    v-if="permission?.view"
                  >
                    <i class="ph-eye"></i>
                  </router-link>
                  <router-link
                    :to="`/product-categories/edit/${productCategory.id}`"
                    class="text-indigo me-1"
                    v-if="permission?.update"
                  >
                    <i class="ph-pen"></i>
                  </router-link>
                  <a
                    href="#"
                    class="text-pink"
                    @click="showConfirmDialog(productCategory)"
                    v-if="permission?.delete"
                    ><i class="ph-trash"></i
                  ></a>
                </td>
              </tr>
            </template>
            <tr v-if="productCategories.loading">
              <td colspan="2" class="text-center">
                <div
                  class="spinner-border text-primary me-2"
                  role="status"
                ></div>
                <div
                  class="spinner-border text-danger me-2"
                  role="status"
                ></div>
                <div class="spinner-border text-success" role="status"></div>
              </td>
            </tr>
            <tr v-if="productCategories.error">
              <td colspan="2">
                <div class="text-danger">
                  Error: {{ productCategories.error }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- /table borders -->

    <template v-if="paging.pages">
      <ul class="pagination justify-content-end">
        <li class="page-item" v-bind:class="paging.older ? '' : 'disabled'">
          <a href="#" class="page-link" @click="changePage(paging.older)"
            >&larr;</a
          >
        </li>
        <li class="page-item" v-for="page in paging.pages" :key="page">
          <a
            href="#"
            class="page-link"
            v-bind:class="page == paging.page ? 'active' : ''"
            @click="changePage(page)"
          >
            {{ page }}</a
          >
        </li>
        <li class="page-item" v-bind:class="paging.newer ? '' : 'disabled'">
          <a href="#" class="page-link" @click="changePage(paging.newer)"
            >&rarr;</a
          >
        </li>
      </ul>
    </template>
  </div>
  <!-- /content area -->
  <ConfirmDialog ref="dialog" :callback="onDelete" />
</template>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useAuthStore, useProductCategoryStore } from "@/stores";
import { alert } from "@/helpers";
import router from "@/router";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

document.title = "Product Category";

const authStore = useAuthStore();
const productCategoryStore = useProductCategoryStore();
const permission = ref();
const dialog = ref();
const { productCategories, paging, option } = storeToRefs(productCategoryStore);
let id = "";

const result = authStore.getPermission("product_category");
if (result?.view) {
  permission.value = result;
} else {
  router.push("/login");
}

productCategoryStore.getProductCategories();

function orderBy(c) {
  option.value.orderBy = c;
  if (option.value.sortBy == "asc") {
    option.value.sortBy = "desc";
  } else {
    option.value.sortBy = "asc";
  }
  productCategoryStore.getProductCategories();
}

function changePage(p) {
  option.value.page = p;
  productCategoryStore.getProductCategories();
}

function onEnter() {
  productCategoryStore.getProductCategories();
}

function onEsc() {
  option.value.keyword = null;
  productCategoryStore.getProductCategories();
}

function showConfirmDialog(productCategory) {
  id = productCategory.id;
  dialog.value.show(`Nhấn OK để xoá.`);
}

async function onDelete() {
  const result = await productCategoryStore.delete(id);
  if (result) {
    alert("Xoá thành công", "bg-teal");
    productCategoryStore.getProductCategories();
  }
}
</script>
