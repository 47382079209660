<template>
  <!-- Content area -->
  <div class="content">
    <!-- Basic layout -->
    <div class="card">
      <div class="card-header d-flex flex-wrap">
        <h6 class="mb-0">Post</h6>
        <div class="d-inline-flex ms-auto">
          <router-link to="/posts" class="text-body"
            ><i class="ph-x"></i
          ></router-link>
        </div>
      </div>

      <div class="card-body">
        <div>
          <div v-if="false" class="row mb-3">
            <label class="col-lg-3 col-form-label">Author:</label>
            <div class="col-lg-9">
              <select
                class="form-control author"
                data-placeholder="Select Author"
              >
                <option></option>
                <option v-if="post.author" :value="post.author?.id">
                  {{ post.author?.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Name:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Name"
                v-model="post.name"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Title:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Title"
                v-model="post.title"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Content:</label>
            <div class="col-lg-9">
              <ckeditor
                class="form-control"
                :editor="editorContent"
                v-model="post.content"
                :config="editorContentConfig"
              ></ckeditor>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Excerpt:</label>
            <div class="col-lg-9">
              <textarea
                rows="3"
                cols="3"
                class="form-control"
                maxlength="511"
                placeholder="Excerpt"
                v-model="post.excerpt"
              ></textarea>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Path:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="1023"
                placeholder="Path"
                v-model="post.path"
              />
            </div>
          </div>
          <div v-if="false" class="row mb-3">
            <label class="col-lg-3 col-form-label">Date:</label>
            <div class="col-lg-9">
              <input type="text" class="form-control date" placeholder="Date" />
            </div>
          </div>
          <!-- <div v-if="!type" class="row mb-3"> -->
          <div v-if="false" class="row mb-3">
            <label class="col-lg-3 col-form-label">Type:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Type"
                v-model="post.type"
              />
            </div>
          </div>
          <!-- <div v-if="!parentId" class="row mb-3"> -->
          <div v-if="false" class="row mb-3">
            <label class="col-lg-3 col-form-label">Parent:</label>
            <div class="col-lg-9">
              <select
                class="form-control parent"
                data-placeholder="Select Parent"
              >
                <option></option>
                <option v-if="post.parent" :value="post.parent?.id">
                  {{ post.parent?.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Sort Order:</label>
            <div class="col-lg-9">
              <input
                type="number"
                class="form-control"
                placeholder="Sort Order"
                v-model="post.sort_order"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Photo:</label>
            <div class="col-lg-9">
              <input
                type="file"
                class="form-control"
                v-on:change="onChangePhoto($event.target.files[0], 'photo')"
              />
              <div class="form-text text-muted" v-if="!post.photo">
                Accepted formats: png, jpg. Max file size 5Mb
              </div>
              <div v-if="post.photo">
                <a :href="API_URL + post.photo.path" target="_blank">
                  <img
                    :src="API_URL + post.photo.path"
                    :alt="post.photo.name"
                    :title="post.photo.name"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="row mb-3" v-if="false">
            <label class="col-lg-3 col-form-label">Status:</label>
            <div class="col-lg-9">
              <select
                data-placeholder="Select Status"
                class="form-control status"
              >
                <option></option>
                <option v-for="s in statuses" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="text-end" v-if="post.id || !id">
            <button type="submit" class="btn btn-primary" @click="onSubmit">
              Save <i class="ph-paper-plane-tilt ms-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /basic layout -->
  </div>
  <!-- /content area -->
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { storeToRefs } from "pinia";
import { useAuthStore, usePostStore } from "@/stores";

import {
  datepicker,
  select,
  selectSearch,
  upload,
  MyUploadAdapter,
  alert,
} from "@/helpers";
import { API_URL } from "@/const";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

document.title = "Post";

const route = useRoute();
const id = route.params.id;
const type = route.query.type;
const parentId = route.query.parent_id;
const authStore = useAuthStore();
const postStore = usePostStore();
const { post, statuses } = storeToRefs(postStore);
post.value = {};

const editorContent = ref(ClassicEditor);
const editorContentConfig = ref({
  mediaEmbed: { previewsInData: true },
  extraPlugins: [
    function (editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
      };
    },
  ],
});

const result = authStore.getPermission("post");
if ((result?.update !== true && id) || (result?.create !== true && !id)) {
  router.push("/login");
}

async function init() {
  selectSearch.init("author", "users", (item) => {
    post.value.author = item;
  });
  datepicker.init("date", (date) => {
    post.value.date = date;
  });
  selectSearch.init("parent", "posts", (item) => {
    post.value.parent = item;
  });
  select.init("status", (item) => {
    post.value.status = parseInt(item.id);
  });
  console.log("parentId", parentId);
  if (id) {
    await postStore.getById(id);
    selectSearch.setValue("author", post.value.author?.id);
    datepicker.setValue("date", post.value.date);
    selectSearch.setValue("parent", post.value.parent?.id);
    select.setValue("status", post.value.status);
  } else {
    if (parentId) {
      post.value.parent = { id: parentId };
    } else if (type) {
      post.value.type = type;
    }
  }
}
function onChangePhoto(file, type) {
  upload(file, `posts/${type}`, (data) => {
    post.value.photo = data;
  });
}

async function onSubmit() {
  if (!post.value.name) {
    alert("Please enter Name");
    return;
  }
  if (!post.value.title) {
    alert("Please enter Title");
    return;
  }
  // if (!post.value.content) {
  //   alert("Please enter Content");
  //   return;
  // }
  // if (!post.value.excerpt) {
  //   alert("Please enter Excerpt");
  //   return;
  // }
  // if (!post.value.path) {
  //   alert("Please enter Path");
  //   return;
  // }
  // if (!post.value.date) {
  //   alert("Please enter Date");
  //   return;
  // }
  // if (!post.value.type) {
  //   alert("Please enter Type");
  //   return;
  // }
  // if (!post.value.parent) {
  //   alert("Please enter Parent");
  //   return;
  // }
  // if (!post.value.sort_order) {
  //   alert("Please enter Sort Order");
  //   return;
  // }
  // if (!post.value.photo) {
  //   alert("Please enter Photo");
  //   return;
  // }
  // if (!post.value.status) {
  //   alert("Please enter Status");
  //   return;
  // }

  const result = await postStore.submit();
  if (result) {
    // router.push("/posts");
    if (parentId) {
      router.push(`/posts?parent_id=${parentId}`);
    } else if (type) {
      router.push(`/posts?type=${type}`);
    } else {
      router.back();
    }
  }
}

onMounted(() => init());
</script>
