<template>
  <!-- Content area -->
  <div class="content">
    <!-- Basic layout -->
    <div class="card">
      <div class="card-header d-flex flex-wrap">
        <h6 class="mb-0">Social</h6>
        <div class="d-inline-flex ms-auto">
          <router-link to="/socials" class="text-body"
            ><i class="ph-x"></i
          ></router-link>
        </div>
      </div>

      <div class="card-body">
        <div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Name:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Name"
                v-model="social.name"
              />
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Link:</label>
            <div class="col-lg-9">
              <textarea
                rows="3"
                cols="3"
                class="form-control"
                maxlength="511"
                placeholder="Link"
                v-model="social.link"
              ></textarea>
            </div>
          </div>
          <div class="row mb-3">
            <label class="col-lg-3 col-form-label">Icon:</label>
            <div class="col-lg-9">
              <input
                type="text"
                class="form-control"
                maxlength="255"
                placeholder="Icon"
                v-model="social.icon"
              />
            </div>
          </div>
          <div class="row mb-3" v-if="false">
            <label class="col-lg-3 col-form-label">Status:</label>
            <div class="col-lg-9">
              <select
                data-placeholder="Select Status"
                class="form-control status"
              >
                <option></option>
                <option v-for="s in statuses" :key="s.id" :value="s.id">
                  {{ s.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="text-end" v-if="social.id || !id">
            <button type="submit" class="btn btn-primary" @click="onSubmit">
              Save <i class="ph-paper-plane-tilt ms-2"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- /basic layout -->
  </div>
  <!-- /content area -->
</template>

<script setup>
import { onMounted } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { storeToRefs } from "pinia";
import { useAuthStore, useSocialStore } from "@/stores";

import { select } from "@/helpers";

document.title = "Social";

const route = useRoute();
const id = route.params.id;
const authStore = useAuthStore();
const socialStore = useSocialStore();
const { social, statuses } = storeToRefs(socialStore);
social.value = {};

const result = authStore.getPermission("social");
if ((result?.update !== true && id) || (result?.create !== true && !id)) {
  router.push("/login");
}

async function init() {
  select.init("status", (item) => {
    social.value.status = parseInt(item.id);
  });
  if (id) {
    await socialStore.getById(id);
    select.setValue("status", social.value.status);
  }
}

async function onSubmit() {
  // if (!social.value.name) {
  //   alert("Please enter Name");
  //   return;
  // }
  // if (!social.value.link) {
  //   alert("Please enter Link");
  //   return;
  // }
  // if (!social.value.icon) {
  //   alert("Please enter Icon");
  //   return;
  // }
  // if (!social.value.status) {
  //   alert("Please enter Status");
  //   return;
  // }

  const result = await socialStore.submit();
  if (result) {
    // router.push("/socials");
    router.back();
  }
}

onMounted(() => init());
</script>
