/* eslint-disable */

import { useAuthStore } from "@/stores/auth.store";
import { API_URL } from "@/const";

export const dropzone = {
  init: init(),
  initView: initView(),
  setValue: setValue(),
};

function init() {
  return (name, folder, onChange) => {
    document.getElementById(
      name
    ).outerHTML = `<div class="dropzone" id="${name}"></div>`;
    Dropzone.instances.forEach((dz) => {
      dz.destroy();
    });
    const { user } = useAuthStore();
    Dropzone.autoDiscover = false;
    let dropzone = new Dropzone("#" + name, {
      method: "POST",
      url: `${API_URL}api/upload`,
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
      paramName: "file",
      dictDefaultMessage: "Drop files to upload",
      maxFilesize: 10, // MB
      maxFiles: 15,
      maxThumbnailFilesize: 1,
      addRemoveLinks: true,
      // acceptedFiles: "image/*",
      init: function () {
        this.on("addedfile", function (file) {
          if (file.type.startsWith("image")) {
            if (file.dataURL?.startsWith("http")) {
              file.previewElement.querySelector("img").src = file.dataURL;
            }
          } else {
            file.previewElement.querySelector(".dz-details").style.opacity = 1;
          }

          file.previewElement.addEventListener("click", () => {
            var win = window.open(file.dataURL, "_blank");
            win.focus();
          });
        });
        this.on("removedfile", function (file) {
          const items = $(".dz-success")
            .map(function () {
              return $(this).attr(name + "-file");
            })
            .get()
            .map((x) => JSON.parse(x));

          onChange(items);
        });
        this.on("sending", function (file, xhr, formData) {
          formData.append("folder", folder || name);
        });
        this.on("success", function (file, response) {
          file.dataURL = API_URL + response.data.path;

          file.previewElement.setAttribute(
            name + "-file",
            JSON.stringify(response.data)
          );

          const items = $(".dz-success")
            .map(function () {
              return $(this).attr(name + "-file");
            })
            .get()
            .map((x) => JSON.parse(x));

          onChange(items);
        });
        this.on("error", function (file, response) {
          file.previewElement.querySelector(
            ".dz-error-message span"
          ).innerHTML = response.message || response;
        });
      },
    });

    return dropzone;
  };
}

function initView() {
  return (name) => {
    document.getElementById(
      name
    ).outerHTML = `<div class="dropzone" id="${name}"></div>`;
    Dropzone.instances.forEach((dz) => {
      dz.destroy();
    });
    Dropzone.autoDiscover = false;
    let dropzone = new Dropzone("#" + name, {
      url: "-",
      dictDefaultMessage: "Nothing here.",
      maxFilesize: 0,
      maxFiles: 0,
      maxThumbnailFilesize: 1,
      addRemoveLinks: false,
      init: function () {
        this.on("addedfile", function (file) {
          if (file.type.startsWith("image")) {
            if (file.dataURL?.startsWith("http")) {
              file.previewElement.querySelector("img").src = file.dataURL;
            }
          } else {
            file.previewElement.querySelector(".dz-details").style.opacity = 1;
          }

          file.previewElement.addEventListener("click", () => {
            var win = window.open(file.dataURL, "_blank");
            win.focus();
          });
        });
        this.on("success", function (file, response) {
          file.dataURL = API_URL + response.data.path;
        });
        this.on("error", function (file, response) {
          file.previewElement.querySelector(
            ".dz-error-message span"
          ).innerHTML = response.message || response;
        });
      },
    });

    document.getElementById(name).style.cursor = "auto";
    dropzone.removeEventListeners();
    return dropzone;
  };
}

function setValue() {
  return (name, values) => {
    const instance = Dropzone.forElement("#" + name);
    instance.removeAllFiles();
    $.each(values, function (i, value) {
      let mockFile = {
        name: value.name,
        dataURL: API_URL + value.path,
        size: value.size,
        type: value.content_type,
        accepted: true,
      };
      instance.emit("addedfile", mockFile);
      instance.files.push(mockFile);
      mockFile.previewElement.setAttribute(
        name + "-file",
        JSON.stringify(value)
      );
      mockFile.previewElement.classList.add("dz-success");
      instance.emit("completed", mockFile);
    });
  };
}
