import { fetchWrapper, alert } from "@/helpers";
import { paging } from "@/helpers/paging";
import { defineStore } from "pinia";

export const useOrderStore = defineStore("order", {
  state: () => ({
    orders: {},
    paging: {},
    option: {},
    order: {},
    statuses: [
      { id: 0, name: "Deleted" },
      { id: 1, name: "Pending" },
      { id: 2, name: "Processing" },
      { id: 3, name: "Awaiting Shipment" },
      { id: 4, name: "Shipping" },
      { id: 5, name: "Completed" },
      { id: 6, name: "Disputed" },
      { id: 7, name: "Refunded" },
      { id: 8, name: "Cancelled" },
    ],
  }),
  actions: {
    async getOrders() {
      // this.orders = { loading: true };
      try {
        let query = [];
        if (this.option.page) {
          query.push(`page=${this.option.page}`);
        }
        if (this.option.keyword) {
          query.push(`keyword=${this.option.keyword.trim()}`);
        }
        if (this.option.orderBy && this.option.sortBy) {
          query.push(`order_by=${this.option.orderBy}`);
          query.push(`sort_by=${this.option.sortBy}`);
        }

        const result = await fetchWrapper.get(
          `api/orders${query.length ? "?" + query.join("&") : ""}`,
        );
        this.orders = result.data;

        // paging
        let p = result.paging;
        this.paging = paging(p.page, p.limit, p.total);
      } catch (error) {
        this.orders = { error };
      }
    },
    async getById(id) {
      try {
        const result = await fetchWrapper.get(`api/orders/${id}`);
        this.order = result.data;
      } catch (error) {
        alert(error);
      }
    },
    async submit() {
      try {
        if (this.order.id) {
          const result = await fetchWrapper.put(
            `api/orders/${this.order.id}`,
            this.order,
          );
          return result;
        } else {
          const result = await fetchWrapper.post("api/orders", this.order);
          return result;
        }
      } catch (error) {
        alert(error);
      }
    },
    async delete(id) {
      try {
        const result = await fetchWrapper.delete(`api/orders/${id}`);
        return result.data;
      } catch (error) {
        alert(error);
      }
    },
  },
});
