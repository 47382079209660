import {
  Layout,
  Login,
  Register,
  ForgotPassword,
  ResetPassword,
  Active,
} from "@/views/authenticate";

export default {
  path: "/",
  component: Layout,
  children: [
    { path: "login", component: Login },
    { path: "register", component: Register },
    { path: "active", component: Active },
    { path: "forgot-password", component: ForgotPassword },
    { path: "reset-password", component: ResetPassword },
  ],
};
